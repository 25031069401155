import React, { useState, useEffect } from 'react';
import axios from 'axios';
import leftArrowImage from '../../assets/images/left-arrow.png';
import ImgUploadImage from '../../assets/images/file_upload.png';

import { NavLink } from "react-router-dom";
import Loading from '../../utils/Loading';
import { toast } from 'react-toastify';
import ModalNewEmployee from './ModalNewEmployee';

const Menues = () => {
    const [menuList, setMenuList] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [menuAllList, setIMenuAllList] = useState([]);
    const [itemsList, setItemsList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [formData, setFormData] = useState({
        name: '',
        image: '',
        parent_id: '',
        items: []
    });

    const resetForm = () => {
        setFormData({
            name: '',
            image: '',
            parent_id: '',
            items: []
        });
    };

    console.log(formData);

    useEffect(() => {
        fetchMenuData();
        fetchData();
        fetchItemsListData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    const fetchMenuData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/restaurant/show-menu-for-item`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
                },
            });
            setMenuList(response.data.menu);
        } catch (error) {
            console.error(error);
        }
    };



    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/restaurant/all-menu-list?page=${currentPage}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
                },
            });

            const { getMenuList } = response.data;
            setIMenuAllList(getMenuList.data);
            setTotalPages(getMenuList.last_page);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchItemsListData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/restaurant/item-list-without-paginate`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
                },
            });

            setItemsList(response.data.result);
        } catch (error) {
            console.error(error);
        }
    };

    const handleMenuChange = (e) => {
        const parent_id = e.target.value;
        setFormData({ ...formData, parent_id });
    };

    const handleSubMenuChange = (e) => {
        const sub_menu_id = e.target.value;
        setFormData({ ...formData, sub_menu_id });
    };

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, image: file });
    };

    const handleItemChange = (restaurant_item_id) => {
        const items = formData.items;
        if (items.some(item => item.restaurant_item_id === restaurant_item_id)) {
            setFormData({
                ...formData,
                items: items.filter((item) => item.restaurant_item_id !== restaurant_item_id)
            });
        } else {
            setFormData({
                ...formData,
                items: [...items, { restaurant_item_id }]
            });
        }
    };

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);
            const response = await axios.post(
                `${process.env.REACT_APP_URL}/restaurant/add-menu`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
                    },
                }
            );

            handleCloseModal();
            fetchData();
            toast.success(response.data.success, { autoClose: 15000 });
            resetForm()
        } catch (error) {
            console.error(error);
            toast.error(error.response.data.error, { autoClose: 15000 });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handlePagination = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            {isLoading ? <Loading /> :
                <div>
                    <ModalNewEmployee isOpen={isModalOpen} onClose={handleCloseModal}>
                        <div className="header">
                            <h2>ایجاد منو جدید</h2>
                        </div>


                        <div className='container'>
                            <div className='d-flex justify-content-center'>
                                <label htmlFor="image-upload" className='upload-img d-flex align-items-center justify-content-center pointer'>
                                    {formData.image ? (
                                        <img src={URL.createObjectURL(formData.image)} alt="Selected Image" className='pointer upload-img' width={32} height={32} />
                                    ) : (
                                        <div className='d-flex align-items-center flex-direction-column'>
                                            <img src={ImgUploadImage} alt="Profile Images" className='pointer' width={32} height={32} />
                                            <span>آپلود تصویر</span>
                                        </div>
                                    )}
                                </label>
                                <input
                                    type="file"
                                    id="image-upload"
                                    name="image"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleImageChange}
                                />
                            </div>

                            <div>
                                <h2 className='mt-8 mb-8'>منو</h2>
                                <div className='f-500 fs-16 mb-4'>نام منو</div>
                                <input
                                    type="text"
                                    name="name"
                                    className="width-480 input-form-dashboard-step mb-24"
                                    placeholder='نام منو'
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </div>


                            <div className='mt-40'>
                                <div className='d-flex align-items-center justify-content-space-between'>
                                    <h2 className='mb-8 mt-8 fs-24'>دسته اصلی</h2>
                                </div>

                                <div className='f-500 fs-16 mb-4'>در صورت انتخاب به عنوان زیر منو ، منو اصلی را از لیست زیر انتخاب کنید</div>
                                <select
                                    name="menu_id"
                                    className="input-form-dashboard-step mb-24 width-480"
                                    onChange={handleMenuChange}
                                    value={formData.menu_id}
                                >
                                    <option value="">منو اصلی را انتخاب کنید</option>
                                    {menuList.map(menu => (
                                        <option key={menu.restaurant_category_id} value={menu.restaurant_category_id}>
                                            {menu.name}
                                        </option>
                                    ))}
                                </select>
                            </div>


                            {/* <div>
                                <h2 className='mt-8 mb-8'>زیر منو</h2>
                                <div className='f-500 fs-16 mb-4'>نام زیر منو</div>
                                <input
                                    type="text"
                                    name="first_name"
                                    className="width-480 input-form-dashboard-step mb-24"
                                    placeholder='نام زیر منو'
                                    value={formData.first_name}
                                    onChange={handleChange}
                                />
                                <div className='f-500'>
                                    + افزودن زیر منو
                                </div>
                            </div> */}



                            <div className='mt-40'>
                                <div className='d-flex align-items-center justify-content-space-between'>
                                    <h2 className='mb-8 mt-8 fs-24'>آیتم ها</h2>
                                </div>

                                <div className='box width-480 mb-8'>
                                    <span>آیتم هایی که میخواهید به این منو اضافه شود را انتخاب کنید</span>

                                    <div className='d-flex flex-direction-column mt-20'>
                                        {itemsList.map(item => (
                                            <div className='d-flex' key={item.restaurant_item_id}>
                                                <input
                                                    type="checkbox"
                                                    checked={formData.items.some(t => t.restaurant_item_id === item.restaurant_item_id)}
                                                    onChange={() => handleItemChange(item.restaurant_item_id)}
                                                />
                                                <span className="checkmark mr-4">{item.name}</span>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>

                        </div>



                        <div className='footer'>
                            <div className='d-flex flx-start'>
                                <div className="form-btn f-700 width-130 ml-16" onClick={handleSubmit} disabled={isSubmitting}>{isSubmitting ? 'در حال ارسال' : 'ایجاد منو'}</div>
                                <div className="form-btn f-700 width-130 form-btn-transparent" onClick={handleCloseModal}>منصرف شدم</div>
                            </div>
                        </div>
                    </ModalNewEmployee>


                    <div className='dashboard-navbar nav-search-row'>
                        <div className='title-40 f-700'>منو ها</div>


                        <div>
                            <div className="button-login" onClick={handleOpenModal}>
                                ایجاد منو جدید
                            </div></div>
                    </div>

                    <div className='table-container'>
                        <div className="main-container">
                            <div className="table-row heading-row">
                                <div className="row-item heading-row f-700">نام منو</div>
                                <div className="row-item heading-row f-700">دسته بندی</div>
                                <div className="row-item justify-content-left">

                                </div>
                            </div>

                            {menuAllList.map((item) => (
                                <div className="table-row" key={item.restaurant_category_id}>
                                    <div className="row-item f-700">
                                        {item.name}
                                    </div>

                                    <div className="row-item f-700">
                                        {item.parent_id === null ? 'منو اصلی' : 'زیر منو'}
                                    </div>

                                    <div className="row-item justify-content-left">
                                        <NavLink to={`/fastfood-dashboard/menues-detail/${item.restaurant_category_id}`}>
                                            <img src={leftArrowImage} alt="Arrow Images" className='pointer' />
                                        </NavLink>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Pagination */}
                    <div className="pagination-container">
                        {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
                            <button
                                key={page}
                                className={`pagination-button ${currentPage === page ? 'active' : ''}`}
                                onClick={() => handlePagination(page)}
                            >
                                {page}
                            </button>
                        ))}
                    </div>

                </div>
            }
        </>
    )
}

export default Menues
