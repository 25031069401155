import React, { useEffect, useState, useRef } from 'react';
import confirmationNumberImage from '../../assets/images/confirmation_number.png';
import deleteImage from '../../assets/images/delete.png';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loading from '../../utils/Loading';
import ModalDelete from '../../common/ModalDelete';

const AdditivesDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [additiveInfo, setAdditiveInfo] = useState({
        name: '',
        price: '',
        additive_id: null,
    });
    const [formData, setFormData] = useState({
        items: []
    });

    console.log(additiveInfo);
    console.log(formData)
        ;
    const [itemList, setItemList] = useState([]);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };


    const fetchItemDetailData = async () => {
        try {
            setIsLoading(true);
            const { data } = await axios.get(`https://jtechnical.ir/kiosk/api/v1/backend/restaurant/additive-info/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('RestaurantToken')}`,
                },
            });

            setAdditiveInfo({
                name: data.additiveInfo.name,
                price: data.additiveInfo.price,
                additive_id: data.additiveInfo.restaurant_additive_id,
            });

            const activeAdditive = data.itemList.filter(additive => additive.status === 'active');
            setFormData({
                items: activeAdditive.map(additive => ({ restaurant_item_id: additive.restaurant_item_id }))
            });
            setItemList(data.itemList);

        } catch (error) {
            console.error('Error fetching service data:', error);
        } finally {
            setIsLoading(false);
        }
    };



    useEffect(() => {
        fetchItemDetailData();
    }, [id]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAdditiveInfo({
            ...additiveInfo,
            [name]: value
        });
    };

    const handleTipChange = (restaurant_item_id) => {
        const items = formData.items;
        if (items.some(additive => additive.restaurant_item_id === restaurant_item_id)) {
            setFormData({
                ...formData,
                items: items.filter((additive) => additive.restaurant_item_id !== restaurant_item_id)
            });
        } else {
            setFormData({
                ...formData,
                items: [...items, { restaurant_item_id }]
            });
        }
    };

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);
            const payload = {
                name: additiveInfo.name,
                price: additiveInfo.price,
                additive_id: additiveInfo.additive_id,
                items: formData.items
            };

            console.log(payload);

            const response = await axios.patch(
                'https://jtechnical.ir/kiosk/api/v1/backend/restaurant/edit-additive',
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
                    },
                }
            );

            toast.success(response.data.success, { autoClose: 15000 });

        } catch (error) {
            console.error(error);
            toast.error(error.response.data.error, { autoClose: 15000 });
        } finally {
            setIsSubmitting(false);
        }
    };


    const handleDelete = async () => {
        try {
            setIsSubmitting(true);
            const response = await axios.delete(`https://jtechnical.ir/kiosk/api/v1/backend/restaurant/delete-additive/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
                },
            });
            toast.success(response.data.message, { autoClose: 15000 });
            navigate('/fastfood-dashboard/additives');
        } catch (error) {
            toast.error(error.response.data.error, { autoClose: 15000 });
        } finally {
            setIsSubmitting(false);
            handleCloseModal();
        }
    };


    return (
        <>
            {isLoading ? <Loading /> :
                <div>

                    <ModalDelete isOpen={isModalOpen} onClose={handleCloseModal}>
                        <div className="header">
                            <h2>حذف آیتم</h2>
                        </div>


                        <div className='container'>
                            <div className='f-500 fs-16 mb-4'>آیا از حذف آیتم مورد نظر مطمئن هستید؟</div>
                        </div>


                        <div className='footer'>
                            <div className='d-flex flx-start'>
                                <div className="form-btn f-700 width-130 ml-16" onClick={handleDelete} disabled={isSubmitting}>{isSubmitting ? 'در حال ارسال' : 'حذف کن'}</div>
                                <div className="form-btn f-700 width-130 form-btn-transparent" onClick={handleCloseModal}>منصرف شدم</div>
                            </div>
                        </div>
                    </ModalDelete>

                    <div className='dashboard-navbar space-between'>
                        <div className='title-40 f-700'>{additiveInfo.name}</div>
                    </div>

                    <div>
                        <div className="main-container d-flex">
                            <div className='user-nav-details width-316'>
                                <div className='item-nav-detail mb-21 pointer'>اطلاعات آیتم</div>
                                <hr />
                                <div className='item-nav-detail mt-21 mb-21 pointer'>افزودنی ها</div>
                            </div>

                            <div className='flex-1'>
                                <div className='mt-0 mr-24 mb-32'>
                                    <div className='header-fastfood'></div>
                                    <div className='footer-personal height-auto relative'>
                                        <div className='d-flex justify-content-space-between height-100 footer-personal-body'>
                                            <div className='d-flex flex-direction-column mt-45'>
                                                <div className='fs-24 f-700'>{additiveInfo.name}</div>
                                                <div className='d-flex f-700 fs-20 color-sub mt-8'>
                                                    <div className='row-h-center sub-text fs-12'>
                                                        <img
                                                            src={confirmationNumberImage}
                                                            alt="Confirm Images"
                                                            className='ml-8'
                                                        />
                                                        {additiveInfo.name}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='f-700 pointer'>
                                                <img
                                                    src={deleteImage}
                                                    alt="Delete"
                                                    onClick={handleOpenModal}
                                                    className='ml-8'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='table-container mt-24 mr-24'>
                                    <div className='user-detail-container'>
                                        <h4 className='mb-8'>اطلاعات آیتم</h4>
                                        <div>
                                            <div className="form-content">
                                                <div className='d-flex space-between'>
                                                    <div>
                                                        <div className='mb-6 f-700'> نام آیتم</div>
                                                        <input
                                                            type="text"
                                                            value={additiveInfo.name}
                                                            onChange={handleInputChange}
                                                            name="name"
                                                            className="input-form-dashboard-step mb-24 width-316"
                                                            placeholder='نام خدمت'
                                                        />
                                                    </div>

                                                    <div>
                                                        <div className='mb-6 f-700'>قیمت</div>
                                                        <input
                                                            type="text"
                                                            value={additiveInfo.price}
                                                            onChange={handleInputChange}
                                                            name="price"
                                                            className="input-form-dashboard-step mb-24 width-316"
                                                            placeholder='قمیت (تومان)'
                                                        />
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='table-container mr-24'>
                                    <div className='user-detail-container'>
                                        <h4 className='mb-4'>آیتم ها</h4>
                                        <div>
                                            <div className="form-content">
                                                <div className='d-flex flex-direction-column mt-20'>
                                                    {itemList.map(item => (
                                                        <div className='d-flex' key={item.restaurant_item_id}>
                                                            <input
                                                                type="checkbox"
                                                                checked={formData.items.some(t => t.restaurant_item_id === item.restaurant_item_id)}
                                                                onChange={() => handleTipChange(item.restaurant_item_id)}
                                                            />
                                                            <span className="checkmark mr-4">{item.name} - ({item.price} تومان)</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className='d-flex justify-content-flex-end mt-15'>
                                    <div className="form-btn f-700 width-130 ml-16 fs-14" onClick={handleSubmit} disabled={isSubmitting}>
                                        {isSubmitting ? 'در حال ارسال' : 'ویرایش افزودنی'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default AdditivesDetail;
