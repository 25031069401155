import React, { useEffect, useState } from 'react';
import serviceListImg from '../../assets/images/service-list-1.png';
import deleteImg from '../../assets/images/delete-factor.png';
import plusImg from '../../assets/images/plus-factor.png';
import minuesImg from '../../assets/images/minues-factor.png';
import Barcode from 'react-barcode';

const FactorList = ({ selectedServices, setSelectedServices }) => {
    
    const [services, setServices] = useState(selectedServices);
    console.log(services);

    useEffect(() => {
        setServices(selectedServices);
    }, [selectedServices]);

    const calculateTotalPrice = () => {
        return services.reduce((total, service) => {
            const servicePrice = parseInt(service.price, 10) || 0;
            let serviceTotal = servicePrice * (service.quantity || 1);
            if (service.additives) {
                service.additives.forEach(additive => {
                    const additivePrice = parseInt(additive.price, 10) || 0;
                    serviceTotal += additivePrice * (additive.quantity || 1);
                });
            }
            return total + serviceTotal;
        }, 0);
    };
    const printContent = () => {
        const printContents = document.querySelector('.print-page').innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    };

    const handleQuantityChange = (service, increment) => {
        const updatedServices = services.map(s => {
            if (s.restaurant_item_id === service.restaurant_item_id) {
                const newQuantity = increment ? (s.quantity || 1) + 1 : Math.max((s.quantity || 1) - 1, 1);
                return { ...s, quantity: newQuantity };
            }
            return s;
        });
        setServices(updatedServices);
        setSelectedServices(updatedServices);
    };

    const handleAdditiveQuantityChange = (service, additive, increment) => {
        const updatedServices = services.map(s => {
            if (s.restaurant_item_id === service.restaurant_item_id) {
                const updatedAdditives = s.additives.map(a => {
                    if (a.restaurant_additive_id === additive.restaurant_additive_id) {
                        const newQuantity = increment ? (a.quantity || 1) + 1 : Math.max((a.quantity || 1) - 1, 1);
                        return { ...a, quantity: newQuantity };
                    }
                    return a;
                });
                return { ...s, additives: updatedAdditives };
            }
            return s;
        });
        setServices(updatedServices);
        setSelectedServices(updatedServices);
    };

    const removeFromSelectedServices = (serviceToRemove) => {
        const updatedServices = services.filter(service => service.restaurant_item_id !== serviceToRemove.restaurant_item_id);
        setServices(updatedServices);
        setSelectedServices(updatedServices);
    };

    const totalPrice = calculateTotalPrice();


    const handlePaymentClick = () => {
        localStorage.setItem('services', JSON.stringify(services));
        const totalPriceInRials = totalPrice * 10;
        const paymentUrl = `http://localhost/kiosk-payment/?amount=${totalPriceInRials}`;
        window.location.href = paymentUrl;
    };
    

    return (
        <div className='factor factor-fastfood-height'>
            <div className="print-page justify-content-center">
                <div className='title-factor fw-700'>فاکتور پرداخت کافه بولوت</div>

                <div className="table-container">
                    <div className="row">
                        <div className="title fw-700">عنوان</div>
                        <div className="price fw-700">قیمت</div>
                    </div>

                    {console.log(services)}
                    {services.map((service, index) => (
                        <div className="row" key={index}>
                            <div className="title">
                                <div className="d-flex align-items-center">
                                    <button onClick={() => handleQuantityChange(service, false)}>-</button>
                                    <span>{service.quantity || 1}</span>
                                    <button onClick={() => handleQuantityChange(service, true)}>+</button>
                                    {service.name}
                                </div>
                            </div>
                            <div className="price">
                                {(parseInt(service.price, 10) * (service.quantity || 1)).toLocaleString()} تومان
                            </div>
                            {service.additives && service.additives.map(additive => (
                                <div key={additive.restaurant_additive_id} className="row">
                                    <div className="title">-- {additive.name} (x{additive.quantity || 1})</div>
                                    <div className="price">{(parseInt(additive.price, 10) * (additive.quantity || 1)).toLocaleString()} تومان</div>
                                    <div className="quantity-controls">
                                        <button onClick={() => handleAdditiveQuantityChange(service, additive, false)}>-</button>
                                        <span>{additive.quantity || 1}</span>
                                        <button onClick={() => handleAdditiveQuantityChange(service, additive, true)}>+</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                    <div className="row total-row">
                        <div className="title fs-12">مجموع:</div>
                        <div className="price fs-12">{totalPrice.toLocaleString()} تومان</div>
                    </div>
                </div>

                <div className='barcode'>
                    <Barcode value={totalPrice.toString()}
                        format="CODE128"
                        width="2"
                        height="30"
                        displayValue={false}
                    />
                </div>
            </div>

            <div className='container d-flex flex-direction-column justify-content-space-between h-100'>
                <div className='d-flex flex-direction-column overflow-auto'>
                    <div className='title mb-42'>فاکتور</div>

                    {services.map((service, index) => (
                        <div className='d-flex flex-direction-column' key={index}>
                            <div className='d-flex'>
                                <img src={serviceListImg} alt='img service list' />
                                <div className='mr-16'>
                                    <div className='fw-700 fs-20'>{service.name}</div>
                                    <div className='fw-700 fs-24'>
                                        {(parseInt(service.price, 10) * (service.quantity || 1)).toLocaleString()} تومان
                                    </div>
                                    {service.additives && service.additives.map(additive => (
                                        <div key={additive.restaurant_additive_id} className="fw-700 fs-16">-- {additive.name} (x{additive.quantity || 1})</div>
                                    ))}
                                </div>
                            </div>

                            <div className='d-flex align-items-center mt-8'>
                                <div className='d-flex align-items-center'>
                                    {service.quantity > 1 ? (
                                        <img
                                            src={minuesImg}
                                            alt='counter img'
                                            className='counter-img pointer'
                                            onClick={() => handleQuantityChange(service, false)}
                                        />
                                    ) : (
                                        <img
                                            src={deleteImg}
                                            alt='delete img'
                                            className='counter-img pointer'
                                            onClick={() => removeFromSelectedServices(service)}
                                        />
                                    )}
                                    <span className='fw-700 fs-32 mx-48'>{service.quantity || 1}</span>

                                    <img
                                        src={plusImg}
                                        alt='counter img'
                                        className='counter-img pointer'
                                        onClick={() => handleQuantityChange(service, true)}
                                    />
                                </div>

                            </div>

                            <hr className=' my-16' />
                        </div>
                    ))}
                </div>

                <div className='factor-footer'>
                    <div className='d-flex justify-content-space-between mb-19'>
                        <div className='text-gray'>مبلغ کل</div>
                        <div className='fw-700 fs-16'>{totalPrice.toLocaleString()} تومان</div>
                    </div>

                    <div className='d-flex justify-content-space-between mb-19'>
                        <div className='text-gray'>مالیات 0 درصد</div>
                        <div className='fw-700 fs-16'>0 تومان</div>
                    </div>

                    <hr className='mb-19' />

                    <div className='d-flex justify-content-space-between mb-19'>
                        <div className='text-gray'>مبلغ قابل پرداخت</div>
                        <div className='fw-700 fs-16'>{totalPrice.toLocaleString()} تومان</div>
                    </div>

                    <button  onClick={handlePaymentClick} className='service-list-button bg-button-black pointer'>پرداخت</button>
                    {/* <button onClick={printContent} className='service-list-button bg-button-black pointer'>پرداخت</button> */}
                </div>
            </div>
        </div>
    );
};

export default FactorList;
