import React, { useState, useEffect } from 'react';
import axios from 'axios';
import seviceListimg from '../../assets/images/service-list-18.jpg';
import plusAdditiveimg from '../../assets/images/plus-item-add.png';
import minuesAdditiveimg from '../../assets/images/minues-item-add.png';


const ServiceList = ({ selectedCategory, selectedServices, setSelectedServices, categoriesMain }) => {
    const [services, setServices] = useState([]);
    const [additives, setAdditives] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const [selectedAdditives, setSelectedAdditives] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                let category = categoriesMain.find(cat => cat.restaurant_category_id === selectedCategory);

                if (!category) {
                    categoriesMain.forEach(mainCat => {
                        if (mainCat.sub_menu) {
                            const subCategory = mainCat.sub_menu.find(subCat => subCat.restaurant_category_id === selectedCategory);
                            if (subCategory) {
                                category = subCategory;
                            }
                        }
                    });
                }

                if (category) {
                    setServices(category.items || []);
                }
            } catch (error) {
                console.error('Error fetching services:', error);
            }
        };

        fetchData();
    }, [selectedCategory, categoriesMain]);

    const addToSelectedServices = async (service) => {
        const isServiceSelected = selectedServices.some(selectedService => selectedService.restaurant_item_id === service.restaurant_item_id);
        if (!isServiceSelected) {
            try {
                const response = await axios.get(`https://jtechnical.ir/kiosk/api/v1/frontend/restaurant/show-additive-for-item/${service.restaurant_item_id}`);
                const { result } = response.data;

                if (result.length > 0) {
                    setAdditives(result);
                    setSelectedService(service);
                    setShowPopup(true);
                } else {
                    setSelectedServices(prevSelectedServices => [...prevSelectedServices, { ...service, quantity: 1 }]);
                }
            } catch (error) {
                console.error('Error fetching additives:', error);
            }
        } else {
            alert('این خدمت قبلاً انتخاب شده است!');
        }
    };

    const handleAdditiveChange = (additive, checked) => {
        setSelectedAdditives(prevSelectedAdditives => ({
            ...prevSelectedAdditives,
            [additive.restaurant_additive_id]: checked ? 1 : 0
        }));
    };

    const handleAdditiveQuantityChange = (additive, increment) => {
        setSelectedAdditives(prevSelectedAdditives => {
            const currentQuantity = prevSelectedAdditives[additive.restaurant_additive_id] || 0;
            const newQuantity = increment ? currentQuantity + 1 : Math.max(currentQuantity - 1, 1);
            return {
                ...prevSelectedAdditives,
                [additive.restaurant_additive_id]: newQuantity
            };
        });
    };

    const handleAdditivesSubmit = () => {
        const selectedAdditiveDetails = additives.filter(additive => selectedAdditives[additive.restaurant_additive_id]);
        const updatedService = {
            ...selectedService,
            quantity: 1, // Set default quantity to 1
            additives: selectedAdditiveDetails.map(additive => ({
                ...additive,
                quantity: selectedAdditives[additive.restaurant_additive_id]
            }))
        };
        setSelectedServices(prevSelectedServices => [...prevSelectedServices, updatedService]);
        setShowPopup(false);
    };


    const handleClosePopup = () => {
        setShowPopup(false);
    };

    console.log(services);

    return (
        <div className='service-list boxes'>
            <div className='container flex-wrap'>
                {services.map((service, index) => (
                    <div className='box' key={index}>
                        <img src={seviceListimg} alt='img service list' className='img-service' />
                        <div className='d-flex justify-content-space-between align-items-center mt-20 mb-16'>
                            <div className='fs-20 fw-700'>{service.name}</div>
                            <div className='d-flex align-items-center price fs-24 fw-700'>{parseInt(service.price).toLocaleString()}<div className='text-gray fs-12 mr-3'>تومان</div></div>
                        </div>
                        <button className='service-list-button bg-button-orange pointer' onClick={() => addToSelectedServices(service)}>انتخاب محصول</button>
                    </div>
                ))}
            </div>
            {showPopup && (
                <div className='popup'>
                    <div className='popup-content'>
                        <h2 className='fs-40 f-700 mb-42'>در صورت تمایل افرودنی های مورد نظر خود را انتحاب کنید</h2>
                        {additives.map(additive => (
                            <div key={additive.restaurant_additive_id} className='additive-item'>
                                <div className='d-flex align-items-center'>
                                    <input
                                        type="checkbox"
                                        id={`additive-${additive.restaurant_additive_id}`}
                                        checked={selectedAdditives[additive.restaurant_additive_id] > 0}
                                        onChange={(e) => handleAdditiveChange(additive, e.target.checked)}
                                    />
                                    <div
                                        className='fs-24 f-700 mr-8'
                                        htmlFor={`additive-${additive.restaurant_additive_id}`}
                                        onClick={() => {
                                            const isChecked = !selectedAdditives[additive.restaurant_additive_id];
                                            document.getElementById(`additive-${additive.restaurant_additive_id}`).click();
                                            handleAdditiveChange(additive, isChecked);
                                        }}
                                    >
                                        {additive.name} - {parseInt(additive.price).toLocaleString()} تومان
                                    </div>
                                </div>

                                {selectedAdditives[additive.restaurant_additive_id] > 0 && (
                                    <div className="quantity-controls">
                                        {/* <button onClick={() => handleAdditiveQuantityChange(additive, false)}>-</button> */}
                                        <img
                                            src={minuesAdditiveimg}
                                            alt='counter img'
                                            className='counter-img pointer'
                                            onClick={() => handleAdditiveQuantityChange(additive, false)}
                                        />
                                        <div className='f-700 fs-24 mx-48'>{selectedAdditives[additive.restaurant_additive_id]}</div>

                                        <img
                                            src={plusAdditiveimg}
                                            alt='counter img'
                                            className='counter-img pointer'
                                            onClick={() => handleAdditiveQuantityChange(additive, true)}
                                        />
                                        {/* <button onClick={() => handleAdditiveQuantityChange(additive, true)}>+</button> */}
                                    </div>
                                )}
                            </div>
                        ))}
                        
                        <div className='d-flex justify-content-center'>
                        <button className="continue bg-button-orange border-0 mt-42 pointer" onClick={handleAdditivesSubmit}>ادامه</button>
                        </div>
                        {/* <button onClick={handleClosePopup}>بستن</button> */}
                    </div>
                </div>
            )}

        </div>
    );
};

export default ServiceList;
