import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import { toast } from 'react-toastify';
import deleteImage from '../../assets/images/delete.png';
import Loading from '../../utils/Loading';
import ModalDelete from '../../common/ModalDelete';

const ServiceDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        image: '',
        beauty_service_id: null,
    });

    console.log(formData);

    const hiddenFileInput = useRef(null);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const fetchServiceDetailData = async () => {
        try {
            setIsLoading(true);
            const { data } = await axios.get(`${process.env.REACT_APP_URL}/beauty/service-info/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('BeautySalonToken')}`,
                },
            });

            setFormData({
                name: data.result.name,
                image: data.result.image,
                beauty_service_id: data.result.beauty_service_id,
            });


        } catch (error) {
            console.error('Error fetching service data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchServiceDetailData();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };




    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);

            const response = await axios.patch(
                `${process.env.REACT_APP_URL}/beauty/edit-service`,
                formData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('BeautySalonToken')}`,
                    },
                }
            );

            toast.success(response.data.success, { autoClose: 15000 });

        } catch (error) {
            console.error(error);
            toast.error(error.response.data.error, { autoClose: 15000 });
        } finally {
            setIsSubmitting(false);
        }
    };


    const handleImageChange = async (event) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            const formData = new FormData();
            formData.append('business_type', 'beauty');
            formData.append('section', 'service');
            formData.append('id', id);
            formData.append('image', fileUploaded);

            try {
                const response = await axios.post(
                    'https://jtechnical.ir/kiosk/api/v1/backend/car-wash/edit-image',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${localStorage.getItem('BeautySalonToken')}`,
                        },
                    }
                );

                setTimeout(() => {
                    window.location.reload();
                }, 2000);

                toast.success('تصویر با موفقیت آپلود شد', { autoClose: 15000 });
            } catch (error) {
                console.error('Error uploading image:', error);
                toast.error('آپلود تصویر با خطا مواجه شد', { autoClose: 15000 });
            }
        }
    };

    const handleImageClick = () => {
        hiddenFileInput.current.click();
    };

    const handleDelete = async () => {
        try {
            setIsSubmitting(true);
            const response = await axios.delete(`https://jtechnical.ir/kiosk/api/v1/backend/beauty/delete-service/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('BeautySalonToken')}`,
                },
            });
            toast.success(response.data.message, { autoClose: 15000 });
            navigate('/salon-dashboard/services');
        } catch (error) {
            toast.error(error.response.data.error, { autoClose: 15000 });
        } finally {
            setIsSubmitting(false);
            handleCloseModal();
        }
    };

    return (
        <>
            {isLoading ? <Loading /> :
                <div>

                    <ModalDelete isOpen={isModalOpen} onClose={handleCloseModal}>
                        <div className="header">
                            <h2>حذف آیتم</h2>
                        </div>


                        <div className='container'>
                            <div className='f-500 fs-16 mb-4'>آیا از حذف آیتم مورد نظر مطمئن هستید؟</div>
                        </div>


                        <div className='footer'>
                            <div className='d-flex flx-start'>
                                <div className="form-btn f-700 width-130 ml-16" onClick={handleDelete} disabled={isSubmitting}>{isSubmitting ? 'در حال ارسال' : 'حذف کن'}</div>
                                <div className="form-btn f-700 width-130 form-btn-transparent" onClick={handleCloseModal}>منصرف شدم</div>
                            </div>
                        </div>
                    </ModalDelete>

                    <div className='dashboard-navbar space-between'>
                        <div className='title-40 f-700'>{formData.name}</div>
                    </div>

                    <div>
                        <div className="main-container d-flex">
                            <div className='user-nav-details width-316'>
                                <div className='item-nav-detail mb-21 pointer'>اطلاعات خدمت</div>
                            </div>

                            <div className='flex-1'>
                                <div className='mt-0 mr-24 mb-32'>
                                    <div className='header-personal'></div>
                                    <div className='footer-personal height-auto relative'>
                                        <img
                                            src={`${process.env.REACT_APP_IMAGE}${formData.image}`}
                                            alt=""
                                            className='user-pic footer-personal-img absolute'
                                            onClick={handleImageClick}
                                            width={56}
                                            height={56}
                                        />
                                        <input
                                            type="file"
                                            ref={hiddenFileInput}
                                            onChange={handleImageChange}
                                            style={{ display: 'none' }}
                                        />


                                        <div className='d-flex justify-content-space-between height-100 footer-personal-body'>
                                            <div className='d-flex flex-direction-column mt-45'>
                                                <div className='fs-24 f-700'>{formData.name}</div>
                                            </div>
                                            <div className='f-700 pointer'>
                                                <img
                                                    src={deleteImage}
                                                    alt="Delete"
                                                    onClick={handleOpenModal}
                                                    className='ml-8'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='table-container mt-24 mr-24'>
                                    <div className='user-detail-container'>
                                        <h4 className='mb-8'>اطلاعات خدمت</h4>
                                        <div>
                                            <div className="form-content">
                                                <div className='d-flex space-between'>
                                                    <div>
                                                        <div className='mb-6 f-700'> نام خدمت</div>
                                                        <input
                                                            type="text"
                                                            value={formData.name}
                                                            onChange={handleInputChange}
                                                            name="name"
                                                            className="input-form-dashboard-step mb-24 width-316"
                                                            placeholder='نام خدمت'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className='d-flex justify-content-flex-end mt-15'>
                                    <div className="form-btn f-700 width-130 ml-16 fs-15" onClick={handleSubmit} disabled={isSubmitting}>
                                        {isSubmitting ? 'در حال ارسال' : 'ویرایش خدمت'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default ServiceDetail;
