import React, { useState, useEffect } from 'react';
import axios from 'axios';
import leftArrowImage from '../../assets/images/left-arrow.png';
import ImgUploadImage from '../../assets/images/file_upload.png';

import { NavLink } from "react-router-dom";
import Loading from '../../utils/Loading';
import { toast } from 'react-toastify';
import ModalNewEmployee from './ModalNewEmployee';

const Items = () => {
  const [menuList, setMenuList] = useState([]);
  const [subMenuList, setSubMenuList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubMenuLoading, setIsSubMenuLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [additivesList, setAdditivesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    image: '',
    menu_id: '',
    sub_menu_id: '',
    additives: []
  });

  const resetForm = () => {
    setFormData({
      name: '',
      price: '',
      image: '',
      menu_id: '',
      sub_menu_id: '',
      additives: []
    });
};

  console.log(formData);

  useEffect(() => {
    fetchMenuData();
    fetchData();
    fetchAdditivesListData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchMenuData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/restaurant/show-menu-for-item`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
        },
      });
      setMenuList(response.data.menu);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSubMenuData = async (restaurant_category_id) => {
    setIsSubMenuLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/restaurant/show-sub-menu-for-item/${restaurant_category_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
        },
      });
      setSubMenuList(response.data.submenu);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubMenuLoading(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/restaurant/item-list?page=${currentPage}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
        },
      });

      const { itemList } = response.data;
      setItemList(itemList.data);
      setTotalPages(itemList.last_page);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAdditivesListData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/restaurant/additive-list-without-paginate`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
        },
      });

      setAdditivesList(response.data.result);
    } catch (error) {
      console.error(error);
    }
  };

  const handleMenuChange = (e) => {
    const menu_id = e.target.value;
    setFormData({ ...formData, menu_id });
    fetchSubMenuData(menu_id);
  };

  const handleSubMenuChange = (e) => {
    const sub_menu_id = e.target.value;
    setFormData({ ...formData, sub_menu_id });
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
  };

  const handleAdditiveChange = (additive_id) => {
    const additives = formData.additives;
    if (additives.some(additive => additive.additive_id === additive_id)) {
      setFormData({
        ...formData,
        additives: additives.filter((additive) => additive.additive_id !== additive_id)
      });
    } else {
      setFormData({
        ...formData,
        additives: [...additives, { additive_id }]
      });
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/restaurant/add-item`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
          },
        }
      );

      handleCloseModal();
      fetchData();
      toast.success(response.data.success, { autoClose: 15000 });
      resetForm()
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.error, { autoClose: 15000 });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
};

  return (
    <>
      {isLoading ? <Loading /> :
        <div>
          <ModalNewEmployee isOpen={isModalOpen} onClose={handleCloseModal}>
            <div className="header">
              <h2>ایجاد آیتم جدید</h2>
            </div>

            <div className='container'>
              <div className='d-flex justify-content-center'>
                <label htmlFor="image-upload" className='upload-img d-flex align-items-center justify-content-center pointer'>
                  {formData.image ? (
                    <img src={URL.createObjectURL(formData.image)} alt="Selected Image" className='pointer upload-img' width={32} height={32} />
                  ) : (
                    <div className='d-flex align-items-center flex-direction-column'>
                      <img src={ImgUploadImage} alt="Profile Images" className='pointer' width={32} height={32} />
                      <span>آپلود تصویر</span>
                    </div>
                  )}
                </label>
                <input
                  type="file"
                  id="image-upload"
                  name="image"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                />
              </div>

              <div>
                <h2 className='mb-8 mt-8 fs-24'>اطلاعات آیتم</h2>

                <div className='f-500 fs-16 mb-4'>نام آیتم</div>
                <input
                  type="text"
                  name="name"
                  className="width-480 input-form-dashboard-step mb-24"
                  placeholder='نام آیتم'
                  value={formData.name}
                  onChange={handleChange}
                />

                <div className='f-500 fs-16 mb-4'>قیمت</div>
                <input
                  type="text"
                  name="price"
                  className="width-480 input-form-dashboard-step mb-24"
                  placeholder='قیمت'
                  value={formData.price}
                  onChange={handleChange}
                />
              </div>

              <div className='mt-40'>
                <div className='d-flex align-items-center justify-content-space-between'>
                  <h2 className='mb-8 mt-8 fs-24'> منو و زیر منو</h2>
                </div>

                <div className='f-500 fs-16 mb-4'>منو</div>
                <select
                  name="menu_id"
                  className="input-form-dashboard-step mb-24 width-480"
                  onChange={handleMenuChange}
                  value={formData.menu_id}
                >
                  <option value="">آیتم را به منو مورد نظر خود اضافه کنید</option>
                  {menuList.map(menu => (
                    <option key={menu.restaurant_category_id} value={menu.restaurant_category_id}>
                      {menu.name}
                    </option>
                  ))}
                </select>

                <div className='f-500 fs-16 mb-4'>زیر منو</div>
                <select
                  name="sub_menu_id"
                  className="input-form-dashboard-step mb-24 width-480"
                  onChange={handleSubMenuChange}
                  value={formData.sub_menu_id}
                >
                  {isSubMenuLoading ? (
                    <option value="">در حال دریافت اطلاعات لطفا صبر کنید</option>
                  ) : (
                    <>
                      <option value="">آیتم را به منو مورد نظر خود اضافه کنید</option>
                      {subMenuList.map(subMenu => (
                        <option key={subMenu.restaurant_category_id} value={subMenu.restaurant_category_id}>
                          {subMenu.name}
                        </option>
                      ))}
                    </>
                  )}
                </select>
              </div>

              <div className='mt-40'>
                <div className='d-flex align-items-center justify-content-space-between'>
                  <h2 className='mb-8 mt-8 fs-24'>افزودنی ها</h2>
                  <div className='f-500 pointer'>+ ایجاد افزودنی جدید</div>
                </div>

                <div className='box width-480 mb-8'>
                  <span>افزودنی هایی که میخواهید به این آیتم اضافه شوند را انتخاب کنید</span>

                  <div className='d-flex flex-direction-column mt-20'>
                    {additivesList.map(additive => (
                      <div className='d-flex' key={additive.additive_id}>
                        <input
                          type="checkbox"
                          checked={formData.additives.some(t => t.additive_id === additive.additive_id)}
                          onChange={() => handleAdditiveChange(additive.additive_id)}
                        />
                        <span className="checkmark mr-4">{additive.name}</span>
                      </div>
                    ))}
                  </div>

                </div>
              </div>
            </div>



            <div className='footer'>
              <div className='d-flex flx-start'>
                <div className="form-btn f-700 width-130 ml-16" onClick={handleSubmit} disabled={isSubmitting}>{isSubmitting ? 'در حال ارسال' : 'ایجاد آیتم'}</div>
                <div className="form-btn f-700 width-130 form-btn-transparent" onClick={handleCloseModal}>منصرف شدم</div>
              </div>
            </div>
          </ModalNewEmployee>


          <div className='dashboard-navbar nav-search-row'>
            <div className='title-40 f-700'>آیتم ها</div>


            <div>
              <div className="button-login" onClick={handleOpenModal}>
                ایجاد آیتم جدید

              </div></div>
          </div>

          <div className='table-container'>
            <div className="main-container">
              <div className="table-row heading-row">
                <div className="row-item heading-row f-700">نام آیتم</div>
                <div className="row-item heading-row f-700">قیمت</div>
                <div className="row-item justify-content-left">

                </div>
              </div>


              {itemList.map((item) => (
                <div className="table-row" key={item.restaurant_item_id}>
                  <div className="row-item f-700">
                    {item.name}
                  </div>

                  <div className="row-item f-700">
                    {item.price}
                  </div>


                  <div className="row-item justify-content-left">
                    <NavLink to={`/fastfood-dashboard/item-detail/${item.restaurant_item_id}`}>
                      <img src={leftArrowImage} alt="Arrow Images" className='pointer' />
                    </NavLink>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Pagination */}
          <div className="pagination-container">
            {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
              <button
                key={page}
                className={`pagination-button ${currentPage === page ? 'active' : ''}`}
              onClick={() => handlePagination(page)}
              >
                {page}
              </button>
            ))}
          </div>

        </div>
      }
    </>
  )
}

export default Items


