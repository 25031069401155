import React, { useEffect, useState, useRef } from 'react';
import confirmationNumberImage from '../../assets/images/confirmation_number.png';
import deleteImage from '../../assets/images/delete.png';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loading from '../../utils/Loading';
import ModalDelete from '../../common/ModalDelete';

const ItemDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [itemInfo, setItemInfo] = useState({
        name: '',
        price: '',
        image: '',
        menu_id: '',
        sub_menu_id: '',
        item_id: null,
    });
    const [formData, setFormData] = useState({
        additives: []
    });

    console.log(itemInfo);

    const [additiveList, setAdditiveList] = useState([]);
    const [menuOptions, setMenuOptions] = useState([]);
    const [subMenuOptions, setSubMenuOptions] = useState([]);

    const hiddenFileInput = useRef(null);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const fetchItemDetailData = async () => {
        try {
            setIsLoading(true);
            const { data } = await axios.get(`https://jtechnical.ir/kiosk/api/v1/backend/restaurant/get-item/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('RestaurantToken')}`,
                },
            });

            setItemInfo({
                name: data.itemInfo.name,
                price: data.itemInfo.price,
                image: data.itemInfo.image,
                menu_id: data.itemInfo.menuIdActive,
                sub_menu_id: data.itemInfo.subMenuIdActive,
                item_id: data.itemInfo.restaurant_item_id,
            });

            const activeAdditive = data.additiveList.filter(additive => additive.status === 'active');
            setFormData({
                additives: activeAdditive.map(additive => ({ additive_id: additive.additive_id }))
            });
            setAdditiveList(data.additiveList);

        } catch (error) {
            console.error('Error fetching service data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchMenuOptions = async () => {
        try {
            const { data } = await axios.get('https://jtechnical.ir/kiosk/api/v1/backend/restaurant/show-menu-for-item',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
                    },
                });
            setMenuOptions(data.menu);
        } catch (error) {
            console.error('Error fetching menu options:', error);
        }
    };

    const fetchSubMenuOptions = async (menuId) => {
        try {
            const { data } = await axios.get(`https://jtechnical.ir/kiosk/api/v1/backend/restaurant/show-sub-menu-for-item/${menuId}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
                    },
                });
            setSubMenuOptions(data.submenu);
            if (data.submenu.length === 0) {
                setItemInfo(prevState => ({ ...prevState, sub_menu_id: null }));
            }
        } catch (error) {
            console.error('Error fetching sub-menu options:', error);
        }
    };


    useEffect(() => {
        fetchItemDetailData();
        fetchMenuOptions();
    }, [id]);

    useEffect(() => {
        if (itemInfo.menu_id) {
            fetchSubMenuOptions(itemInfo.menu_id);
        }
    }, [itemInfo.menu_id]);



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setItemInfo(prevState => ({
            ...prevState,
            [name]: value,
            ...(name === "menu_id" && { sub_menu_id: '' })
        }));
    };


    const handleTipChange = (additive_id) => {
        const additives = formData.additives;
        if (additives.some(additive => additive.additive_id === additive_id)) {
            setFormData({
                ...formData,
                additives: additives.filter((additive) => additive.additive_id !== additive_id)
            });
        } else {
            setFormData({
                ...formData,
                additives: [...additives, { additive_id }]
            });
        }
    };

    const handleImageChange = async (event) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {

            const formData = new FormData();
            formData.append('business_type', 'restaurant');
            formData.append('section', 'item');
            formData.append('id', id);
            formData.append('image', fileUploaded);

            try {
                const response = await axios.post(
                    'https://jtechnical.ir/kiosk/api/v1/backend/car-wash/edit-image',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${localStorage.getItem('RestaurantToken')}`,
                        },
                    }
                );

                setTimeout(() => {
                    window.location.reload();
                }, 2000);

                toast.success('تصویر با موفقیت آپلود شد', { autoClose: 15000 });
            } catch (error) {
                console.error('Error uploading image:', error);
                toast.error('آپلود تصویر با خطا مواجه شد', { autoClose: 15000 });
            }
        }
    };

    const handleImageClick = () => {
        hiddenFileInput.current.click();
    };

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);
            const payload = {
                name: itemInfo.name,
                price: itemInfo.price,
                item_id: itemInfo.item_id,
                menu_id: itemInfo.menu_id,
                sub_menu_id: itemInfo.sub_menu_id,
                additives: formData.additives
            };

            const response = await axios.patch(
                'https://jtechnical.ir/kiosk/api/v1/backend/restaurant/edit-item',
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
                    },
                }
            );

            toast.success(response.data.success, { autoClose: 15000 });

        } catch (error) {
            console.error(error);
            toast.error(error.response.data.error, { autoClose: 15000 });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleDelete = async () => {
        try {
            setIsSubmitting(true);
            const response = await axios.delete(`https://jtechnical.ir/kiosk/api/v1/backend/restaurant/delete-item/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
                },
            });
            toast.success(response.data.message, { autoClose: 15000 });
            navigate('/fastfood-dashboard/items');
        } catch (error) {
            toast.error(error.response.data.error, { autoClose: 15000 });
        } finally {
            setIsSubmitting(false);
            handleCloseModal();
        }
    };

    return (
        <>
            {isLoading ? <Loading /> :
                <div>

                    <ModalDelete isOpen={isModalOpen} onClose={handleCloseModal}>
                        <div className="header">
                            <h2>حذف آیتم</h2>
                        </div>


                        <div className='container'>
                            <div className='f-500 fs-16 mb-4'>آیا از حذف آیتم مورد نظر مطمئن هستید؟</div>
                        </div>


                        <div className='footer'>
                            <div className='d-flex flx-start'>
                                <div className="form-btn f-700 width-130 ml-16" onClick={handleDelete} disabled={isSubmitting}>{isSubmitting ? 'در حال ارسال' : 'حذف کن'}</div>
                                <div className="form-btn f-700 width-130 form-btn-transparent" onClick={handleCloseModal}>منصرف شدم</div>
                            </div>
                        </div>
                    </ModalDelete>

                    <div className='dashboard-navbar space-between'>
                        <div className='title-40 f-700'>{itemInfo.name}</div>
                    </div>

                    <div>
                        <div className="main-container d-flex">
                            <div className='user-nav-details width-316'>
                                <div className='item-nav-detail mb-21 pointer'>اطلاعات آیتم</div>
                                <hr />
                                <div className='item-nav-detail mt-21 mb-21 pointer'>افزودنی ها</div>
                            </div>

                            <div className='flex-1'>
                                <div className='mt-0 mr-24 mb-32'>
                                    <div className='header-fastfood'></div>
                                    <div className='footer-personal height-auto relative'>
                                        <img
                                            src={`${process.env.REACT_APP_IMAGE}${itemInfo.image}`}
                                            alt=""
                                            className='user-pic footer-personal-img absolute'
                                            onClick={handleImageClick}
                                            width={56}
                                            height={56}
                                        />
                                        <input
                                            type="file"
                                            ref={hiddenFileInput}
                                            onChange={handleImageChange}
                                            style={{ display: 'none' }}
                                        />

                                        <div className='d-flex justify-content-space-between height-100 footer-personal-body'>
                                            <div className='d-flex flex-direction-column mt-45'>
                                                <div className='fs-24 f-700'>{itemInfo.name}</div>
                                                <div className='d-flex f-700 fs-20 color-sub mt-8'>
                                                    <div className='row-h-center sub-text fs-12'>
                                                        <img
                                                            src={confirmationNumberImage}
                                                            alt="Confirm Images"
                                                            className='ml-8'
                                                        />
                                                        {itemInfo.name}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='f-700 pointer'>
                                                <img
                                                    src={deleteImage}
                                                    alt="Delete"
                                                    onClick={handleOpenModal}
                                                    className='ml-8'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='table-container mt-24 mr-24'>
                                    <div className='user-detail-container'>
                                        <h4 className='mb-8'>اطلاعات آیتم</h4>
                                        <div>
                                            <div className="form-content">
                                                <div className='d-flex space-between'>
                                                    <div>
                                                        <div className='mb-6 f-700'> نام آیتم</div>
                                                        <input
                                                            type="text"
                                                            value={itemInfo.name}
                                                            onChange={handleInputChange}
                                                            name="name"
                                                            className="input-form-dashboard-step mb-24 width-316"
                                                            placeholder='نام خدمت'
                                                        />
                                                    </div>

                                                    <div>
                                                        <div className='mb-6 f-700'>قیمت</div>
                                                        <input
                                                            type="text"
                                                            value={itemInfo.price}
                                                            onChange={handleInputChange}
                                                            name="price"
                                                            className="input-form-dashboard-step mb-24 width-316"
                                                            placeholder='قمیت (تومان)'
                                                        />
                                                    </div>
                                                </div>

                                                <div className='d-flex space-between'>
                                                    <div>
                                                        <div className='mb-6 f-700'>منو</div>
                                                        <select
                                                            name="menu_id"
                                                            value={itemInfo.menu_id}
                                                            onChange={handleInputChange}
                                                            className="input-form-dashboard-step mb-24 width-316">
                                                            <option value="">آیتم را به منو مورد نظر خود اضافه کنید</option>
                                                            {menuOptions.map(option => (
                                                                <option key={option.restaurant_category_id} value={option.restaurant_category_id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>


                                                    {itemInfo.menu_id && subMenuOptions.length > 0 && (
                                                        <div>
                                                            <div className='mb-6 f-700'>زیر منو</div>
                                                            <select
                                                                name="sub_menu_id"
                                                                value={itemInfo.sub_menu_id}
                                                                onChange={handleInputChange}
                                                                className="input-form-dashboard-step mb-24 width-316">
                                                                <option value="">آیتم را به منو مورد نظر خود اضافه کنید</option>
                                                                {subMenuOptions.map(option => (
                                                                    <option key={option.restaurant_category_id} value={option.restaurant_category_id}>
                                                                        {option.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    )}

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='table-container mr-24'>
                                    <div className='user-detail-container'>
                                        <h4 className='mb-4'>افزودنی ها</h4>
                                        <div>
                                            <div className="form-content">
                                                <div className='d-flex flex-direction-column mt-20'>
                                                    {additiveList.map(additive => (
                                                        <div className='d-flex' key={additive.additive_id}>
                                                            <input
                                                                type="checkbox"
                                                                checked={formData.additives.some(t => t.additive_id === additive.additive_id)}
                                                                onChange={() => handleTipChange(additive.additive_id)}
                                                            />
                                                            <span className="checkmark mr-4">{additive.name} - ({additive.price} تومان)</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className='d-flex justify-content-flex-end mt-15'>
                                    <div className="form-btn f-700 width-130 ml-16 fs-15" onClick={handleSubmit} disabled={isSubmitting}>
                                        {isSubmitting ? 'در حال ارسال' : 'ویرایش آیتم'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default ItemDetail;
