import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../index.css';
import keyboardArrowRightImage from '../assets/images/keyboard_arrow_right.png';
import axios from 'axios';
const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [mobile, setMobile] = useState('');

    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
          setIsLoading(true);
          const response = await axios.post(
            `${process.env.REACT_APP_AUTH}/manager-login`,
            { mobile }
          );


          if(response.data.success){
            toast.success(response.data.success, { autoClose: 5000 });
            navigate(`/verify`, { state: { mobile } });

          }
    

        } catch (error) {
          console.error('Error during login', error);
          toast.error(error.response.data.error, { autoClose: 15000 });

        } finally {
          setIsLoading(false);
        }
      };


    return (
        <div className='max-342'>
            <div className="back-btn">
                <img
                src={keyboardArrowRightImage}
                alt="Keyboard Arrow Right"
                className="arrow-image pointer"
                onClick={() => navigate(-1)}
            />بازگشت</div>
            
            <div className='height-100vh'>
            <div className="height-100">
                <div className='login-container text-center'>
                    <h2 className="text-center mb-24">ورود به حساب کاربری</h2>
                    <input type="text" className="input-login mb-24" value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder='مثال: ۰۹۱۹۵۸۹۸۹۵۲' />

                    <button className="button-login" disabled={isLoading} onClick={handleLogin}>
                        {isLoading ? 'در حال ارسال کد' : 'ارسال کد'}
                    </button>
                </div>
            </div>
            </div>
        </div>

    );
};

export default Login;
