import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import confirmationNumberImage from '../../assets/images/confirmation_number.png';
import deleteImage from '../../assets/images/delete.png';
import Loading from '../../utils/Loading';
import { toast } from 'react-toastify';
import ModalDelete from '../../common/ModalDelete';

const EditTypeCar = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        brands: [],
        services: []
    });
    const [brandsData, setBrandsData] = useState([]);
    const [servicesData, setServicesData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const { data } = await axios.get(`${process.env.REACT_APP_URL}/car-wash/get-tip/${id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('CarWashToken')}`
                    }
                });

                setFormData({
                    name: data.tipInfo.name,
                    description: data.tipInfo.description,
                    brands: data.branInfo.map(brand => ({
                        car_brand_id: brand.car_brand_id,
                        type: brand.type.filter(type => type.status === 'active').map(type => ({
                            car_type_id: type.car_type_id
                        }))
                    })),
                    services: data.serviceInfo.filter(service => service.status === 'active').map(service => ({
                        car_service_id: service.car_service_id
                    }))
                });
                setBrandsData(data.branInfo);
                setServicesData(data.serviceInfo);
            } catch (error) {
                console.error(error);
                toast.error('خطا در دریافت اطلاعات', { autoClose: 5000 });
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleBrandChange = (brandId, typeId) => {
        const updatedBrands = formData.brands.map(brand => {
            if (brand.car_brand_id === brandId) {
                const typeIndex = brand.type.findIndex(type => type.car_type_id === typeId);
                if (typeIndex === -1) {
                    brand.type.push({ car_type_id: typeId });
                } else {
                    brand.type.splice(typeIndex, 1);
                }
            }
            return brand;
        });
        setFormData({ ...formData, brands: updatedBrands });
    };

    const handleServiceChange = (serviceId) => {
        const updatedServices = formData.services.some(service => service.car_service_id === serviceId) ?
            formData.services.filter(service => service.car_service_id !== serviceId) :
            [...formData.services, { car_service_id: serviceId }];
        setFormData({ ...formData, services: updatedServices });
    };

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);

            // Filter out brands with empty type arrays
            const filteredBrands = formData.brands.filter(brand => brand.type.length > 0);

            const editData = {
                name: formData.name,
                description: formData.description,
                car_tip_id: id, // Assuming you get id from URL params
                brands: filteredBrands,
                services: formData.services
            };

            const response = await axios.patch(`${process.env.REACT_APP_URL}/car-wash/edit-tip`, editData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('CarWashToken')}`
                }
            });

            toast.success(response.data.success, { autoClose: 15000 });
        } catch (error) {
            console.error(error);
            toast.error(error.response.data.error, { autoClose: 15000 });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleDelete = async () => {
        try {
            setIsSubmitting(true);
            const response = await axios.delete(`https://jtechnical.ir/kiosk/api/v1/backend/car-wash/delete-tip/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('CarWashToken')}`,
                },
            });
            toast.success(response.data.message, { autoClose: 15000 });
            navigate('/carvash-dashboard/types');
        } catch (error) {
            toast.error(error.response.data.error, { autoClose: 15000 });
        } finally {
            setIsSubmitting(false);
            handleCloseModal();
        }
    };


    if (isLoading) {
        return <Loading />;
    }

    return (
        <div>


            <ModalDelete isOpen={isModalOpen} onClose={handleCloseModal}>
                <div className="header">
                    <h2>حذف آیتم</h2>
                </div>


                <div className='container'>
                    <div className='f-500 fs-16 mb-4'>آیا از حذف آیتم مورد نظر مطمئن هستید؟</div>
                </div>


                <div className='footer'>
                    <div className='d-flex flx-start'>
                        <div className="form-btn f-700 width-130 ml-16" onClick={handleDelete} disabled={isSubmitting}>{isSubmitting ? 'در حال ارسال' : 'حذف کن'}</div>
                        <div className="form-btn f-700 width-130 form-btn-transparent" onClick={handleCloseModal}>منصرف شدم</div>
                    </div>
                </div>
            </ModalDelete>

            <div className='dashboard-navbar space-between'>
                <div className='title-40 f-700'>{formData.name}</div>

            </div>


            <div>
                <div className="main-container d-flex">
                    <div className='user-nav-details width-316'>
                        <div className='item-nav-detail mb-21 pointer'>اطلاعات تیپ</div>
                        <hr />
                        <div className='item-nav-detail mt-21 mb-21 pointer'>خدمات</div>
                    </div>

                    <div className='flex-1'>
                        <div className='mt-0 mr-24 mb-32'>
                            <div className='header-personal'></div>
                            <div className='footer-personal height-auto relative'>
                                <div className='d-flex justify-content-space-between height-100 footer-personal-body'>
                                    <div className='d-flex flex-direction-column mt-45'>
                                        <div className='fs-24 f-700'>{formData.name}</div>
                                        <div className='d-flex f-700 fs-20 color-sub mt-8'>
                                            <div className='row-h-center sub-text fs-12'>
                                                <img
                                                    src={confirmationNumberImage}
                                                    alt="Confirm Images"
                                                    className='ml-8'
                                                />
                                                {formData.description}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='f-700 pointer'>
                                        <img
                                            src={deleteImage}
                                            alt="Delete"
                                            onClick={handleOpenModal}
                                            className='ml-8'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='table-container mt-24 mr-24'>
                            <div className='user-detail-container'>
                                <h4 className='mb-8'>اطلاعات تیپ</h4>
                                <div>
                                    <div className="form-content">
                                        <div className='d-flex space-between'>
                                            <div>
                                                <div className='mb-6 f-700'>اطلاعات تیپ</div>
                                                <input
                                                    type="text"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    name="name"
                                                    className="input-form-dashboard-step mb-24 width-316"
                                                    placeholder='اطلاعات تیپ'
                                                />
                                            </div>

                                        </div>

                                        <div className='d-flex'>
                                            <div className='flex'>
                                                <div className='mb-6 f-700'>توضیحات</div>
                                                <input
                                                    type='text'
                                                    value={formData.description}
                                                    onChange={handleChange}
                                                    name="description"
                                                    className="input-form-dashboard-step mb-24 width-316"
                                                    placeholder='توضیحات' /
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='table-container mt-24 mr-24'>
                            <div className='user-detail-container'>
                                <h4 className='mb-8'>برند و نوع ماشین</h4>
                                <p className='mb-8'>برای برند، نوع ماشینی را که در  این تیپ قرار میگیرد را انتخاب کنید</p>
                                <div>
                                    <div className="form-content brands">

                                        {brandsData.map((brand) => (
                                            <div key={brand.car_brand_id} className='box width-480 mb-8'>
                                                <div className='color-black fs-18 f-700'>{brand.name}</div>
                                                <div className='d-flex mt-8'>
                                                    {brand.type.map((type) => (
                                                        <div key={type.car_type_id} className='ml-8'>
                                                            <input
                                                                type="checkbox"
                                                                checked={formData.brands.some(b =>
                                                                    b.car_brand_id === brand.car_brand_id &&
                                                                    b.type.some(t => t.car_type_id === type.car_type_id)
                                                                )}
                                                                onChange={() => handleBrandChange(brand.car_brand_id, type.car_type_id)}
                                                            />
                                                            <span className="checkmark mr-4">{type.type_name}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='table-container mt-24 mr-24'>
                            <div className='user-detail-container'>
                                <h4 className='mb-8'>خدمات</h4>
                                <p className='mb-16'>خدماتی که به این تیپ ارائه می‌شوند را انتخاب کنید</p>
                                <div className='box width-480 d-flex flex-direction-column'>
                                    <div className='d-flex'>
                                        <input
                                            type="checkbox"
                                            name="toggle"
                                            className="toggle-button"
                                            checked={servicesData.length > 0 && formData.services.length === servicesData.length}
                                            onChange={() => setFormData({
                                                ...formData,
                                                services: formData.services.length === servicesData.length ? [] : servicesData.map(service => ({ car_service_id: service.car_service_id }))
                                            })}
                                        />
                                        <label htmlFor="toggle-button-4" className="f-700 mr-4 color-black">
                                            این خدمت را به همه تیپ ماشین ها اضافه کن
                                        </label>
                                    </div>

                                    <div className='d-flex flex-direction-column mt-20'>
                                        {servicesData.map((service) => (
                                            <div key={service.car_service_id} className='d-flex justify-content-space-between'>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        checked={formData.services.some(s => s.car_service_id === service.car_service_id)}
                                                        onChange={() => handleServiceChange(service.car_service_id)}
                                                    />
                                                    <span className="checkmark mr-10">{service.name}</span>
                                                </div>

                                                <div>
                                                    {service.price}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='d-flex justify-content-flex-end mt-15'>
                            <div className="form-btn f-700 width-130 ml-16" onClick={handleSubmit} disabled={isSubmitting}>
                                {isSubmitting ? 'در حال ارسال' : 'ویرایش تیپ'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='container'>
                <div className='f-500 fs-16 mb-4'>نام تیپ</div>
                <input
                    type="text"
                    name="name"
                    className="width-480 input-form-dashboard-step mb-24"
                    placeholder='نام تیپ'
                    value={formData.name}
                    onChange={handleChange}
                />

                <div className='f-500 fs-16 mb-4'>توضیحات</div>
                <input
                    type="text"
                    name="description"
                    className="width-480 input-form-dashboard-step mb-24"
                    placeholder='توضیحات'
                    value={formData.description}
                    onChange={handleChange}
                />

                <div>
                    <h2>برند و نوع ماشین</h2>
                    <p className='fs-14 mb-8'>برای برند، نوع ماشینی را که در این تیپ قرار میگیرد را انتخاب کنید</p>
                    {brandsData.map((brand) => (
                        <div key={brand.car_brand_id} className='box width-480 mb-8'>
                            <div className='color-black fs-18 f-700'>{brand.name}</div>
                            <div className='d-flex mt-8'>
                                {brand.type.map((type) => (
                                    <div key={type.car_type_id} className='ml-8'>
                                        <input
                                            type="checkbox"
                                            checked={formData.brands.some(b =>
                                                b.car_brand_id === brand.car_brand_id &&
                                                b.type.some(t => t.car_type_id === type.car_type_id)
                                            )}
                                            onChange={() => handleBrandChange(brand.car_brand_id, type.car_type_id)}
                                        />
                                        <span className="checkmark mr-4">{type.type_name}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                <div className='mt-24'>
                    <h2>خدمت</h2>
                    <p className='fs-14 mb-8'>خدماتی که میخواهید به این دسته بندی اضافه شوند را انتخاب کنید و سپس از طریق صفحه تیپ ماشین ها، قیمت هرکدام از خدمات را وارد کنید.</p>

                    <div className='box width-480 d-flex flex-direction-column'>
                        <div className='d-flex'>
                            <input
                                type="checkbox"
                                name="toggle"
                                className="toggle-button"
                                checked={servicesData.length > 0 && formData.services.length === servicesData.length}
                                onChange={() => setFormData({
                                    ...formData,
                                    services: formData.services.length === servicesData.length ? [] : servicesData.map(service => ({ car_service_id: service.car_service_id }))
                                })}
                            />
                            <label htmlFor="toggle-button-4" className="f-700 mr-4 color-black">
                                این خدمت را به همه تیپ ماشین ها اضافه کن
                            </label>
                        </div>

                        <div className='d-flex flex-direction-column mt-20'>
                            {servicesData.map((service) => (
                                <div key={service.car_service_id} className='d-flex justify-content-space-between'>
                                    <div>
                                        <input
                                            type="checkbox"
                                            checked={formData.services.some(s => s.car_service_id === service.car_service_id)}
                                            onChange={() => handleServiceChange(service.car_service_id)}
                                        />
                                        <span className="checkmark mr-10">{service.name}</span>
                                    </div>

                                    <div>
                                        {service.price}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </div> */}

            {/* <div className='footer'>
                <div className='d-flex flx-start'>
                    <div className="form-btn f-700 width-130 ml-16" onClick={handleSubmit} disabled={isSubmitting}>
                        {isSubmitting ? 'در حال ارسال' : 'ویرایش تیپ'}
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default EditTypeCar;
