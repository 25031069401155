import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loading from '../../utils/Loading';
import confirmationNumberImage from '../../assets/images/confirmation_number.png';
import deleteImage from '../../assets/images/delete.png';
import ModalDelete from '../../common/ModalDelete';

const EmployeeDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [servicesList, setServicesList] = useState([]);
    const [selectedServices, setSelectedServices] = useState({});
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        national_code: '',
        mobile: '',
        image: '',
        services: []
    });

    console.log(formData);

    const hiddenFileInput = useRef(null);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };


    const fetchEmployeeData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/beauty/get-staff-info/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('BeautySalonToken')}`,
                },
            });

            const { staffInfo, service } = response.data;

            const activeServices = service.filter(s => s.status === 'active');

            setFormData({
                first_name: staffInfo.first_name,
                last_name: staffInfo.last_name,
                national_code: staffInfo.national_code,
                mobile: staffInfo.mobile,
                image: staffInfo.image,
                services: activeServices.map(s => ({
                    service_id: s.service_id,
                    price: s.price || '',
                    commission: s.commission || ''
                }))
            });

            const initialSelectedServices = {};
            activeServices.forEach(s => {
                initialSelectedServices[s.service_id] = {
                    price: s.price || '',
                    commission: s.commission || ''
                };
            });
            setSelectedServices(initialSelectedServices);

            const servicesResponse = await axios.get(`${process.env.REACT_APP_URL}/beauty/service-list-without-paginate`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('BeautySalonToken')}`,
                },
            });
            setServicesList(servicesResponse.data.result);

        } catch (error) {
            console.error(error);
            toast.error("Failed to fetch employee data", { autoClose: 15000 });
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        fetchEmployeeData();
    }, [id]);

    const handleCheckboxChange = (e, serviceId) => {
        const { checked } = e.target;
        setSelectedServices((prev) => ({
            ...prev,
            [serviceId]: checked ? { price: '', commission: '' } : undefined,
        }));

        setFormData((prevFormData) => {
            const updatedServices = checked
                ? [...prevFormData.services, { service_id: serviceId, price: '', commission: '' }]
                : prevFormData.services.filter((service) => service.service_id !== serviceId);

            return { ...prevFormData, services: updatedServices };
        });
    };

    const handleServiceChange = (e, serviceId, field) => {
        const { value } = e.target;
        setSelectedServices((prev) => ({
            ...prev,
            [serviceId]: {
                ...prev[serviceId],
                [field]: value,
            },
        }));

        setFormData((prevFormData) => ({
            ...prevFormData,
            services: prevFormData.services.map((service) =>
                service.service_id === serviceId ? { ...service, [field]: value } : service
            ),
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);

            const response = await axios.patch(
                `${process.env.REACT_APP_URL}/beauty/edit-staff`,
                { ...formData, beauty_staff_id: id },
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('BeautySalonToken')}`,
                    },
                }
            );

            toast.success(response.data.success, { autoClose: 15000 });
        } catch (error) {
            console.error(error);
            toast.error(error.response.data.error, { autoClose: 15000 });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleImageChange = async (event) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            const formDataChangeImage = new FormData();
            formDataChangeImage.append('business_type', 'beauty');
            formDataChangeImage.append('section', 'staff');
            formDataChangeImage.append('id', id);
            formDataChangeImage.append('image', fileUploaded);

            try {
                const response = await axios.post(
                    'https://jtechnical.ir/kiosk/api/v1/backend/car-wash/edit-image',
                    formDataChangeImage,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${localStorage.getItem('BeautySalonToken')}`,
                        },
                    }
                );

                setTimeout(() => {
                    window.location.reload();
                }, 2000);

                toast.success('تصویر با موفقیت آپلود شد', { autoClose: 15000 });
            } catch (error) {
                console.error('Error uploading image:', error);
                toast.error('آپلود تصویر با خطا مواجه شد', { autoClose: 15000 });
            }
        }
    };

    const handleImageClick = () => {
        hiddenFileInput.current.click();
    };

    const handleDelete = async () => {
        try {
            setIsSubmitting(true);
            const response = await axios.delete(`https://jtechnical.ir/kiosk/api/v1/backend/beauty/delete-staff/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('BeautySalonToken')}`,
                },
            });
            toast.success(response.data.message, { autoClose: 15000 });
            navigate('/salon-dashboard/employees');
        } catch (error) {
            toast.error(error.response.data.error, { autoClose: 15000 });
        } finally {
            setIsSubmitting(false);
            handleCloseModal();
        }
    };

    return (
        <>
            {isLoading ? <Loading /> :
                <div>

                    <ModalDelete isOpen={isModalOpen} onClose={handleCloseModal}>
                        <div className="header">
                            <h2>حذف آیتم</h2>
                        </div>


                        <div className='container'>
                            <div className='f-500 fs-16 mb-4'>آیا از حذف آیتم مورد نظر مطمئن هستید؟</div>
                        </div>


                        <div className='footer'>
                            <div className='d-flex flx-start'>
                                <div className="form-btn f-700 width-130 ml-16" onClick={handleDelete} disabled={isSubmitting}>{isSubmitting ? 'در حال ارسال' : 'حذف کن'}</div>
                                <div className="form-btn f-700 width-130 form-btn-transparent" onClick={handleCloseModal}>منصرف شدم</div>
                            </div>
                        </div>
                    </ModalDelete>


                    <div className='dashboard-navbar space-between'>
                        <div className='title-40 f-700'>ویرایش کارمند</div>
                    </div>

                    <div className="main-container d-flex">
                        <div className='user-nav-details width-316'>
                            <div className='item-nav-detail mb-21 pointer'>اطلاعات کارمند</div>
                            <hr />
                            <div className='item-nav-detail mt-21 mb-21 pointer'>خدمات</div>
                        </div>

                        <div className='flex-1'>
                            <div className='mt-0 mr-24 mb-32'>
                                <div className='header-fastfood'></div>
                                <div className='footer-personal height-auto relative'>
                                    <img
                                        src={`${process.env.REACT_APP_IMAGE}${formData.image}`}
                                        alt=''
                                        className='user-pic footer-personal-img absolute'
                                        onClick={handleImageClick}
                                        width={56}
                                        height={56}
                                    />
                                    <input
                                        type="file"
                                        ref={hiddenFileInput}
                                        onChange={handleImageChange}
                                        style={{ display: 'none' }}
                                    />
                                    <div className='d-flex justify-content-space-between height-100 footer-personal-body'>
                                        <div className='d-flex flex-direction-column mt-45'>
                                            <div className='fs-24 f-700'>{formData.first_name} {formData.last_name}</div>
                                            <div className='d-flex f-700 fs-20 color-sub mt-8'>
                                                <div className='row-h-center sub-text fs-12'>
                                                    <img src={confirmationNumberImage} alt="Confirm Images" className='ml-8' />
                                                    {formData.first_name} {formData.last_name}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='f-700 pointer'>
                                            <img
                                                src={deleteImage}
                                                alt="Delete"
                                                onClick={handleOpenModal}
                                                className='ml-8'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='table-container mt-24 mr-24'>
                                <div className='user-detail-container'>
                                    <h4 className='mb-8'>اطلاعات کارمند</h4>
                                    <div className="form-content">
                                        <div className='d-flex'>
                                            <div>
                                                <div className='mb-6 f-700'>نام کارمند</div>
                                                <input
                                                    type="text"
                                                    name="first_name"
                                                    className="input-form-dashboard-step mb-24 width-316"
                                                    placeholder='نام کارمند'
                                                    value={formData.first_name}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            <div>
                                                <div className='mb-6 f-700'>نام خانوادگی کارمند</div>
                                                <input
                                                    type="text"
                                                    name="last_name"
                                                    className="input-form-dashboard-step mb-24 width-316"
                                                    placeholder='نام خانوادگی کارمند'
                                                    value={formData.last_name}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            <div>
                                                <div className='mb-6 f-700'>شماره موبایل کارمند</div>
                                                <input
                                                    type="text"
                                                    name="mobile"
                                                    className="input-form-dashboard-step mb-24 width-316"
                                                    placeholder='شماره موبایل کارمند'
                                                    value={formData.mobile}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            <div>
                                                <div className='mb-6 f-700'>کد ملی کارمند</div>
                                                <input
                                                    type="text"
                                                    name="national_code"
                                                    className="input-form-dashboard-step mb-24 width-316"
                                                    placeholder='کد ملی کارمند'
                                                    value={formData.national_code}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='table-container mr-24'>
                                <div className='user-detail-container'>
                                    <h4 className='mb-4'>خدمات</h4>
                                    <div className="form-content">
                                        <div className='mt-24'>
                                            <h2>خدمات</h2>
                                            <div className='box width-480 mb-8'>
                                                <p className='fs-14 mb-8'>خدماتی که توسط این کارمند ارائه می‌شوند را انتخاب کنید</p>
                                                <div className='d-flex flex-direction-column mt-20'>
                                                    {servicesList.map(item => (
                                                        <div className='d-flex flex-direction-column mb-4' key={item.service_id}>
                                                            <div className='d-flex align-items-center mb-4'>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={!!selectedServices[item.service_id]}
                                                                    onChange={(e) => handleCheckboxChange(e, item.service_id)}
                                                                />
                                                                <span className="checkmark mr-4">{item.name}</span>
                                                            </div>
                                                            {selectedServices[item.service_id] && (
                                                                <div className='d-flex flex-direction-column mb-10'>
                                                                    <input
                                                                        type="number"
                                                                        className="w-100 input-form-dashboard-step mb-8"
                                                                        placeholder='قیمت'
                                                                        value={selectedServices[item.service_id].price}
                                                                        onChange={(e) => handleServiceChange(e, item.service_id, 'price')}
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        className="w-100 input-form-dashboard-step mt-5"
                                                                        placeholder='کمیسیون'
                                                                        value={selectedServices[item.service_id].commission}
                                                                        onChange={(e) => handleServiceChange(e, item.service_id, 'commission')}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex justify-content-flex-end mt-15'>
                                <button className="form-btn f-700 width-130 ml-16 fs-14" onClick={handleSubmit} disabled={isSubmitting}>
                                    {isSubmitting ? 'در حال ارسال' : 'ویرایش کارمند'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default EmployeeDetail;
