import React, { useState, useEffect } from 'react';
import axios from 'axios';
import leftArrowImage from '../../assets/images/left-arrow.png';
import { NavLink } from "react-router-dom";
import Loading from '../../utils/Loading';
import { toast } from 'react-toastify';
import ModalNewEmployee from './ModalNewEmployee';

const Additives = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [additiveList, setAdditiveList] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    items: []
  });

  const resetForm = () => {
    setFormData({
      name: '',
      price: '',
      items: []
    });
};

  console.log(formData);

  useEffect(() => {
    fetchData();
    fetchItemsListData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [currentPage]);



  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/restaurant/additive-list?page=${currentPage}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
        },
      });

      const { result } = response.data;
      setAdditiveList(result.data);
      setTotalPages(result.last_page);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchItemsListData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/restaurant/item-list-without-paginate`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
        },
      });

      setItemsList(response.data.result);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleItemChange = (restaurant_item_id) => {
    const items = formData.items;
    if (items.some(item => item.restaurant_item_id === restaurant_item_id)) {
      setFormData({
        ...formData,
        items: items.filter((item) => item.restaurant_item_id !== restaurant_item_id)
      });
    } else {
      setFormData({
        ...formData,
        items: [...items, { restaurant_item_id }]
      });
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/restaurant/add-additive`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
          },
        }
      );

      handleCloseModal();
      fetchData();
      toast.success(response.data.success, { autoClose: 15000 });
      resetForm()
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.error, { autoClose: 15000 });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      {isLoading ? <Loading /> :
        <div>
          <ModalNewEmployee isOpen={isModalOpen} onClose={handleCloseModal}>
            <div className="header">
              <h2>ایجاد افزودنی جدید</h2>
            </div>


            <div className='container'>

              <div>
                <div className='f-500 fs-16 mb-4'>نام افزودنی </div>
                <input
                  type="text"
                  name="name"
                  className="width-480 input-form-dashboard-step mb-24"
                  placeholder='نام افزودنی'
                  value={formData.name}
                  onChange={handleChange}
                />

                <div className='f-500 fs-16 mb-4'>قیمت افزودنی</div>
                <input
                  type="text"
                  name="price"
                  className="width-480 input-form-dashboard-step mb-24"
                  placeholder='قمیت افزودنی (تومان)'
                  value={formData.price}
                  onChange={handleChange}
                />
              </div>



              <div className='mt-40'>
                <div className='d-flex align-items-center justify-content-space-between'>
                  <h2 className='mb-8 mt-8 fs-24'>آیتم ها</h2>
                </div>

                <div className='box width-480 mb-8'>
                  <span>آیتم هایی که میخواهید به این افزودنی اضافه شوند را انتخاب کنید</span>

                  <div className='d-flex flex-direction-column mt-20'>
                    {itemsList.map(item => (
                      <div className='d-flex' key={item.restaurant_item_id}>
                        <input
                          type="checkbox"
                          checked={formData.items.some(t => t.restaurant_item_id === item.restaurant_item_id)}
                          onChange={() => handleItemChange(item.restaurant_item_id)}
                        />
                        <span className="checkmark mr-4">{item.name}</span>
                      </div>
                    ))}
                  </div>

                </div>
              </div>

            </div>



            <div className='footer'>
              <div className='d-flex flx-start'>
                <div className="form-btn f-700 width-130 ml-16" onClick={handleSubmit} disabled={isSubmitting}>{isSubmitting ? 'در حال ارسال' : 'ایجاد افزودنی'}</div>
                <div className="form-btn f-700 width-130 form-btn-transparent" onClick={handleCloseModal}>منصرف شدم</div>
              </div>
            </div>
          </ModalNewEmployee>


          <div className='dashboard-navbar nav-search-row'>
            <div className='title-40 f-700'>افزودنی ها</div>


            <div>
              <div className="button-login" onClick={handleOpenModal}>
                ایجاد افزودنی جدید
              </div></div>
          </div>

          <div className='table-container'>
            <div className="main-container">
              <div className="table-row heading-row">
                <div className="row-item heading-row f-700">نام افزودنی</div>
                <div className="row-item heading-row f-700">قیمت</div>
                <div className="row-item justify-content-left">

                </div>
              </div>

              {additiveList.map((item) => (
                <div className="table-row" key={item.restaurant_additive_id}>
                  <div className="row-item f-700">
                    {item.name}
                  </div>

                  <div className="row-item f-700">
                    {item.price}
                  </div>

                  <div className="row-item justify-content-left">
                    <NavLink to={`/fastfood-dashboard/additive-detail/${item.restaurant_additive_id}`}>
                      <img src={leftArrowImage} alt="Arrow Images" className='pointer' />
                    </NavLink>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Pagination */}
          <div className="pagination-container">
            {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
              <button
                key={page}
                className={`pagination-button ${currentPage === page ? 'active' : ''}`}
                onClick={() => handlePagination(page)}
              >
                {page}
              </button>
            ))}
          </div>

        </div>
      }
    </>
  )
}

export default Additives
