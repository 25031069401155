import React, { useEffect, useState } from 'react';
import logOutImage from '../../assets/images/logout-dashboard.png';

import itemsBlackImage from '../../assets/images/items-black.png';
import itemsWhiteImage from '../../assets/images/items-white.png';

import menuBlackImage from '../../assets/images/menu_black.png';
import menuWhiteImage from '../../assets/images/menu_white.png';

import additivesBlackImage from '../../assets/images/additives-black.png';
import additivesWhiteImage from '../../assets/images/additives-white.png';

import reportBlackImage from '../../assets/images/report-black.png';
import reportWhiteImage from '../../assets/images/report-white.png';

import settingsBlackImage from '../../assets/images/settings-black.png';
import settingsWhiteImage from '../../assets/images/settings-white.png';

import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const FastFoodSidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();


    const handleLogout = () => {
        localStorage.clear();
        navigate('/login');
    };

    const isItemsPath = /\/fastfood-dashboard\/items/.test(location.pathname);
    const isMenuPath = /\/fastfood-dashboard\/menues/.test(location.pathname);
    const isAdditivesPath = /\/fastfood-dashboard\/additives/.test(location.pathname);
    const isReportsPath = /\/fastfood-dashboard\/reports/.test(location.pathname);
    const isSettingsPath = /\/fastfood-dashboard\/settings/.test(location.pathname);

    return (
        <div className='dashboard-sidebar'>
            <div className='dashboard-sidebar-container'>

                <div className='sidebar-user-online'>
                    <div className='py-20 space-around flx-column justify-content-space-between height-100'>
                        <h2 className='color-white'>خوش آمدید</h2>
                        
                        <div>
                            <div className='fs-18 f-700'>آخرین همسانسازی:</div>
                            <div className='fs-18 f-700'>سه شنبه، ۱۴ خرداد | ساعت ۱۸:۳۰</div>
                        </div>
                    </div>
                </div>

                <div className='mt-24'>

                    <div className={`link-item fs-20 pointer ${isItemsPath ? 'link-item-active' : ''}`} onClick={() => navigate('/fastfood-dashboard/items')}>
                        <img src={isItemsPath ? itemsWhiteImage : itemsBlackImage} alt="items Images" className='ml-8' />
                        آیتم ها
                    </div>

                    <div className={`link-item fs-20 pointer ${isMenuPath ? 'link-item-active' : ''}`} onClick={() => navigate('/fastfood-dashboard/menues')}>
                        <img src={isMenuPath ? menuWhiteImage : menuBlackImage} alt="menu Images" className='ml-8' />
                        منو ها
                    </div>

                    <div className={`link-item fs-20 pointer ${isAdditivesPath ? 'link-item-active' : ''}`} onClick={() => navigate('/fastfood-dashboard/additives')}>
                        <img src={isAdditivesPath ? additivesWhiteImage : additivesBlackImage} alt="additives Images" className='ml-8' />
                        افزودنی ها
                    </div>

                    <div className={`link-item fs-20 pointer ${isReportsPath ? 'link-item-active' : ''}`} onClick={() => navigate('/fastfood-dashboard/reports')}>
                        <img src={isReportsPath ? reportWhiteImage : reportBlackImage} alt="report Images" className='ml-8' />
                        گزارشات
                    </div>

                    <div className={`link-item fs-20 pointer ${isSettingsPath ? 'link-item-active' : ''}`} onClick={() => navigate('/fastfood-dashboard/settings')}>
                        <img src={isSettingsPath ? settingsWhiteImage : settingsBlackImage} alt="settings Images" className='ml-8' />
                        تنظیمات
                    </div>
                </div>

                <div className='d-flex sidebar-logout pointer' onClick={handleLogout}>
                    <img src={logOutImage} alt="Logout Images" />
                    <h3 className='mr-12'>خروج از حساب کاربری</h3>
                </div>
            </div>
        </div>
    );
};

export default FastFoodSidebar;
