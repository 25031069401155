import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loading from '../../utils/Loading';

const Settings = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    mobile: '',
    store_name: '',
    national_code: '',
    invoice_title: '',
    text_below_invoice: '',
    number_print: '',
    manager_setting_id: null
  });

  useEffect(() => {
    const fetchSettings = async () => {
      setIsLoading(true);
      try {
        const { data } = await axios.get(
          'https://jtechnical.ir/kiosk/api/v1/backend/setting/get-setting',
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
            },
          }
        );
        setFormData({ ...data.settingInfo });
      } catch (error) {
        console.error('Error fetching settings:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const payload = {
      user_id: formData.manager_setting_id,
      ...formData
    };

    try {
      const response = await axios.patch('https://jtechnical.ir/kiosk/api/v1/backend/setting/edit-setting', payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
          },
        }

      );
      toast.success(response.data.success, { autoClose: 15000 });

      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isLoading ? <Loading /> :
        <div>
          <div className='dashboard-navbar space-between'>
            <div className='title-40 f-700'>تنظیمات</div>
          </div>

          <div className='table-container mt-24'>
            <div className='user-detail-container'>
              <h4 className='mb-4'>اطلاعات فروشگاه</h4>
              <div>
                <div className="form-content">
                  <div className='d-flex'>
                    <div>
                      <div className='mb-6 mt-16 f-700'>نام فروشگاه</div>
                      <input
                        type="text"
                        name="store_name"
                        className="input-form-dashboard-step mb-24 width-480"
                        placeholder='نام فروشگاه'
                        value={formData.store_name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className='d-flex'>
                    <div>
                      <div className='mb-6 f-700'>نام مدیر</div>
                      <input
                        type="text"
                        name="first_name"
                        className="input-form-dashboard-step mb-24 width-480"
                        placeholder='نام مدیر'
                        value={formData.first_name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className='d-flex'>
                    <div>
                      <div className='mb-6 f-700'>نام خانوادگی مدیر</div>
                      <input
                        type="text"
                        name="last_name"
                        className="input-form-dashboard-step mb-24 width-480"
                        placeholder='نام خانوادگی مدیر'
                        value={formData.last_name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className='d-flex'>
                    <div>
                      <div className='mb-6 f-700'>شماره موبایل مدیر</div>
                      <input
                        type="text"
                        name="mobile"
                        className="input-form-dashboard-step mb-24 width-480"
                        placeholder='شماره موبایل مدیر'
                        value={formData.mobile}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className='d-flex'>
                    <div>
                      <div className='mb-6 f-700'>شماره ملی مدیر</div>
                      <input
                        type="text"
                        name="national_code"
                        className="input-form-dashboard-step mb-24 width-480"
                        placeholder='شماره ملی مدیر'
                        value={formData.national_code}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='table-container mt-24'>
            <div className='user-detail-container'>
              <h4 className='mb-4'>اطلاعات کیوسک</h4>
              <div>
                <div className="form-content">
                  <div className='d-flex'>
                    <div>
                      <div className='mb-6 f-700'>متن نمایشی در عنوان فاکتور</div>
                      <input
                        type="text"
                        name="invoice_title"
                        className="input-form-dashboard-step mb-24 width-480"
                        placeholder='متن نمایشی در عنوان فاکتور'
                        value={formData.invoice_title}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className='d-flex'>
                    <div>
                      <div className='mb-6 f-700'>متن نمایشی در زیر فاکتور</div>
                      <input
                        type="text"
                        name="text_below_invoice"
                        className="input-form-dashboard-step mb-24 width-480"
                        placeholder='متن نمایشی در زیر فاکتور'
                        value={formData.text_below_invoice}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className='d-flex'>
                    <div>
                      <div className='mb-6 f-700'>تعداد چاپ</div>
                      <input
                        type="text"
                        name="number_print"
                        className="input-form-dashboard-step mb-24 width-480"
                        placeholder='تعداد چاپ'
                        value={formData.number_print}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='mt-10'>
            <div className='d-flex justify-content-flex-end'>
              <button
                className="form-btn f-700 width-130 ml-16"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'در حال ارسال' : 'ویرایش'}
              </button>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default Settings;
