import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import '../index.css';
import keyboardArrowRightImage from '../assets/images/keyboard_arrow_right.png';
import { useAuth } from '../contexts/AuthContext';
const Verify = () => {
    const { dispatch } = useAuth();
    const { state } = useLocation();
    const { mobile } = state;
    const [code, setCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();


    const handleVerify = async () => {
        try {
            setIsLoading(true);

            const response = await axios.post(
                `${process.env.REACT_APP_AUTH}/confirm-code`,
                { mobile, code }
            );

            const data = response.data;

            console.log(data);

            localStorage.setItem(`${data.bussiness_name}Token`, data.success.token);



            dispatch({
                type: 'LOGIN',
                payload: {
                    salonToken: data.bussiness_name === 'BeautySalon' ? data.success.token : null,
                    carvashToken: data.bussiness_name === 'CarWash' ? data.success.token : null,
                    restaurantToken: data.bussiness_name === 'Restaurant' ? data.success.token : null,
                    role: data.bussiness_name,
                },
            });

            switch (data.bussiness_name) {
                case 'BeautySalon':
                    navigate('/salon-dashboard/');
                    break;
                case 'CarWash':
                    navigate('/carvash-dashboard');
                    break;

                case 'Restaurant':
                    navigate('/fastfood-dashboard');
                    break;

                default:
                    break;
            }


        } catch (error) {
            toast.error(error.response.data.error, { autoClose: 5000 });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='max-342'>
            <div className="back-btn pointer" onClick={() => navigate(-1)}>
                <img
                    src={keyboardArrowRightImage}
                    alt="Keyboard Arrow Right"
                    className="arrow-image"

                />بازگشت</div>
            <div className='height-100vh'>
                <div className="height-100">
                    <div className='login-container'>
                        <h2>کد تایید</h2>
                        <p className="sub-text mb-24">لطفا کد ارسال شده به {mobile} را وارد کنید.</p>
                        <input type="text" className="input-login mb-24" placeholder='کد تایید' onChange={(e) => setCode(e.target.value)} maxLength={4} />

                        <button className="button-login mb-24" disabled={isLoading} onClick={handleVerify}>
                            {isLoading ? 'در حال ورود' : 'ورود'}
                        </button>

                        <p className="sub-verify text-center">کد تایید را دریافت نکرده اید؟</p>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Verify;
