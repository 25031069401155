import React, { useState, useEffect } from 'react';
import axios from 'axios';
import leftArrowImage from '../../assets/images/left-arrow.png';
import ImgUploadImage from '../../assets/images/file_upload.png';
import { NavLink } from "react-router-dom";
import Loading from '../../utils/Loading';
import { toast } from 'react-toastify';
import ModalNewEmployee from './ModalNewEmployee';

const Employees = () => {
    const [employeesListData, setEmployeesListData] = useState([]);
    const [servicesList, setServicesList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);

    const initialFormData = {
        first_name: '',
        last_name: '',
        mobile: '',
        national_code: '',
        image: '',
        services: []
    };

    const initialSelectedServices = {};

    const [formData, setFormData] = useState(initialFormData);
    const [selectedServices, setSelectedServices] = useState(initialSelectedServices);


    console.log(formData);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, image: file });
    };

    const fetchServicesListData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/beauty/service-list-without-paginate`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('BeautySalonToken')}`,
                },
            });

            setServicesList(response.data.result);
        } catch (error) {
            console.error(error);
        }
    };

    const handleCheckboxChange = (e, serviceId) => {
        const { checked } = e.target;
        setSelectedServices((prev) => ({
            ...prev,
            [serviceId]: checked ? { price: '', commission: '' } : undefined,
        }));

        if (checked) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                services: [...prevFormData.services, { service_id: serviceId, price: '', commission: '' }],
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                services: prevFormData.services.filter((service) => service.service_id !== serviceId),
            }));
        }
    };

    const handleServiceChange = (e, serviceId, field) => {
        const { value } = e.target;
        setSelectedServices((prev) => ({
            ...prev,
            [serviceId]: {
                ...prev[serviceId],
                [field]: value,
            },
        }));

        setFormData((prevFormData) => ({
            ...prevFormData,
            services: prevFormData.services.map((service) =>
                service.service_id === serviceId ? { ...service, [field]: value } : service
            ),
        }));
    };

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);

            const response = await axios.post(
                `${process.env.REACT_APP_URL}/beauty/add-staff`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${localStorage.getItem('BeautySalonToken')}`,
                    },
                }
            );

            handleCloseModal();
            fetchData();

            toast.success(response.data.success, { autoClose: 15000 });
            setFormData(initialFormData);
            setSelectedServices(initialSelectedServices);



        } catch (error) {
            console.error(error);
            toast.error(error.response.data.error, { autoClose: 15000 });

        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        fetchData();
        fetchServicesListData();
    }, [currentPage]);

    const fetchData = async () => {
        setIsLoading(true)
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/beauty/staff-list?page=${currentPage}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('BeautySalonToken')}`,
                },
            });

            const { result } = response.data;
            setEmployeesListData(result.data);
            setTotalPages(result.last_page);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false)
        }
    };

    const handlePagination = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            {isLoading ? <Loading /> :
                <div>
                    <ModalNewEmployee isOpen={isModalOpen} onClose={handleCloseModal}>
                        <div className="header">
                            <h2>ایجاد کارمند جدید</h2>
                        </div>

                        <div className='container'>
                            <div className='d-flex justify-content-center'>
                                <label htmlFor="image-upload" className='upload-img d-flex align-items-center justify-content-center pointer'>
                                    {formData.image ? (
                                        <img src={URL.createObjectURL(formData.image)} alt="Selected Image" className='pointer upload-img' width={32} height={32} />
                                    ) : (
                                        <div className='d-flex align-items-center flex-direction-column'>
                                            <img src={ImgUploadImage} alt="Profile Images" className='pointer' width={32} height={32} />
                                            <span>آپلود تصویر</span>
                                        </div>
                                    )}
                                </label>
                                <input
                                    type="file"
                                    id="image-upload"
                                    name="image"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleImageChange}
                                />
                            </div>

                            <div>
                                <h2 className='mb-8'>اطلاعات خدمت</h2>

                                <div className='f-500 fs-16 mb-4'>نام کارمند</div>
                                <input
                                    type="text"
                                    name="first_name"
                                    className="width-480 input-form-dashboard-step mb-24"
                                    placeholder='نام کارمند'
                                    value={formData.first_name}
                                    onChange={handleChange}
                                />

                                <div className='f-500 fs-16 mb-4'>نام خانوادگی کارمند</div>
                                <input
                                    type="text"
                                    name="last_name"
                                    className="width-480 input-form-dashboard-step mb-24"
                                    placeholder='نام خانوادگی کارمند'
                                    value={formData.last_name}
                                    onChange={handleChange}
                                />

                                <div className='f-500 fs-16 mb-4'>شماره موبایل کارمند</div>
                                <input
                                    type="text"
                                    name="mobile"
                                    className="width-480 input-form-dashboard-step mb-24"
                                    placeholder='شماره موبایل کارمند'
                                    value={formData.mobile}
                                    onChange={handleChange}
                                />

                                <div className='f-500 fs-16 mb-4'>کد ملی کارمند</div>
                                <input
                                    type="text"
                                    name="national_code"
                                    className="width-480 input-form-dashboard-step mb-24"
                                    placeholder='کد ملی کارمند'
                                    value={formData.national_code}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='mt-24'>
                                <h2>خدمات</h2>
                                <div className='box width-480 mb-8'>
                                    <p className='fs-14 mb-8'>خدماتی که توسط این کارمند ارائه می‌شوند را انتخاب کنید</p>

                                    <div className='d-flex flex-direction-column mt-20'>
                                        {servicesList.map(item => (
                                            <div className='d-flex flex-direction-column mb-4' key={item.service_id}>
                                                <div className='d-flex align-items-center mb-4'>
                                                    <input
                                                        type="checkbox"
                                                        checked={!!selectedServices[item.service_id]}
                                                        onChange={(e) => handleCheckboxChange(e, item.service_id)}
                                                    />
                                                    <span className="checkmark mr-4">{item.name}</span>
                                                </div>
                                                {selectedServices[item.service_id] && (
                                                    <div className='d-flex flex-direction-column mb-10'>
                                                        <input
                                                            type="number"
                                                            className="w-100 input-form-dashboard-step mb-8"
                                                            placeholder='قیمت'
                                                            value={selectedServices[item.service_id].price}
                                                            onChange={(e) => handleServiceChange(e, item.service_id, 'price')}
                                                        />
                                                        <input
                                                            type="number"
                                                            className="w-100 input-form-dashboard-step mt-5"
                                                            placeholder='کمیسیون'
                                                            value={selectedServices[item.service_id].commission}
                                                            onChange={(e) => handleServiceChange(e, item.service_id, 'commission')}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='footer'>
                            <div className='d-flex flx-start'>
                                <button className="form-btn f-700 width-130 ml-16" onClick={handleSubmit} disabled={isSubmitting}>
                                    {isSubmitting ? 'در حال ارسال' : 'ایجاد کامند'}
                                </button>
                                <button className="form-btn f-700 width-130 form-btn-transparent" onClick={handleCloseModal}>منصرف شدم</button>
                            </div>
                        </div>
                    </ModalNewEmployee>

                    <div className='dashboard-navbar nav-search-row'>
                        <div className='title-40 f-700'>کارمندان</div>
                        <div>
                            <div className="button-login" onClick={handleOpenModal}>
                                ایجاد کارمند جدید
                            </div>
                        </div>
                    </div>

                    <div className='table-container'>
                        <div className="main-container">
                            <div className="table-row heading-row">
                                <div className="row-item heading-row f-700">نام کارمند</div>
                                <div className="row-item heading-row f-700">شماره تماس</div>
                                <div className="row-item justify-content-left">
                                </div>
                            </div>

                            {employeesListData.map((employee) => (
                                <div className="table-row" key={employee.beauty_staff_id}>
                                    <div className="row-item f-700">
                                        {employee.first_name} {employee.last_name}
                                    </div>
                                    <div className="row-item f-700">
                                        {employee.mobile}
                                    </div>
                                    <div className="row-item justify-content-left">
                                        <NavLink to={`/salon-dashboard/employee-detail/${employee.beauty_staff_id}`}>
                                            <img src={leftArrowImage} alt="Arrow Images" className='pointer' />
                                        </NavLink>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Pagination */}
                    <div className="pagination-container">
                        {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
                            <button
                                key={page}
                                className={`pagination-button ${currentPage === page ? 'active' : ''}`}
                                onClick={() => handlePagination(page)}
                            >
                                {page}
                            </button>
                        ))}
                    </div>
                </div>
            }
        </>
    );
}

export default Employees;
