import React, { useState, useEffect } from 'react';
import axios from 'axios';
import leftArrowImage from '../../assets/images/left-arrow.png';
import ImgUploadImage from '../../assets/images/file_upload.png';
import { NavLink } from "react-router-dom";
import Loading from '../../utils/Loading';
import { toast } from 'react-toastify';
import ModalNewEmployee from './ModalNewEmployee';

const Services = () => {
  const [serviceListData, setServiceListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [tipList, setTipList] = useState([]);
  const [selectAllTips, setSelectAllTips] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    price: '',
    description: '',
    image: '',
    tips: []
  });

  const resetForm = () => {
    setFormData({
      name: '',
      price: '',
      description: '',
      image: '',
      tips: []
    });
};

  console.log(formData);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
  };

  const handleTipChange = (car_tip_id) => {
    const tips = formData.tips;
    if (tips.some(tip => tip.car_tip_id === car_tip_id)) {
      setFormData({
        ...formData,
        tips: tips.filter((tip) => tip.car_tip_id !== car_tip_id)
      });
    } else {
      setFormData({
        ...formData,
        tips: [...tips, { car_tip_id }]
      });
    }
  };


  const handleSelectAllTips = (e) => {
    setSelectAllTips(e.target.checked);
    if (e.target.checked) {
      const allTipIds = tipList.map(tip => ({ car_tip_id: tip.car_tip_id }));
      setFormData({ ...formData, tips: allTipIds });
    } else {
      setFormData({ ...formData, tips: [] });
    }
  };




  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/car-wash/add-service`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('CarWashToken')}`,
          },
        }
      );

      handleCloseModal();
      fetchData();


      toast.success(response.data.success, { autoClose: 15000 });
      resetForm()
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.error, { autoClose: 15000 });

    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchTipData();
  }, [currentPage]);

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/car-wash/service-list?page=${currentPage}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('CarWashToken')}`,
        },
      });

      const { serviceList } = response.data;
      setServiceListData(serviceList.data);
      setTotalPages(serviceList.last_page);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false)
    }
  };

  const fetchTipData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/car-wash/tip-list-without-paginate`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('CarWashToken')}`,
        },
      });

      setTipList(response.data.tipList);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      {isLoading ? <Loading /> :
        <div>
          <ModalNewEmployee isOpen={isModalOpen} onClose={handleCloseModal}>
            <div className="header">
              <h2>افزودن خدمت جدید</h2>
            </div>

            <div className='container'>
              <div className='d-flex justify-content-center'>
                <label htmlFor="image-upload" className='upload-img d-flex align-items-center justify-content-center pointer'>
                  {formData.image ? (
                    <img src={URL.createObjectURL(formData.image)} alt="Selected Image" className='pointer upload-img' width={32} height={32} />
                  ) : (
                    <div className='d-flex align-items-center flex-direction-column'>
                      <img src={ImgUploadImage} alt="Profile Images" className='pointer' width={32} height={32} />
                      <span>آپلود تصویر</span>
                    </div>
                  )}
                </label>
                <input
                  type="file"
                  id="image-upload"
                  name="image"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                />
              </div>

              <div className='f-500 fs-16 mb-4'>نام خدمت</div>
              <input
                type="text"
                name="name"
                className="width-480 input-form-dashboard-step mb-24"
                placeholder='نام خدمت'
                value={formData.name}
                onChange={handleChange}
              />

              <div className='f-500 fs-16 mb-4'>قیمت</div>
              <input
                type="text"
                name="price"
                className="width-480 input-form-dashboard-step mb-24"
                placeholder='قیمت'
                value={formData.price}
                onChange={handleChange}
              />

              <div className='f-500 fs-16 mb-4'>توضیحات</div>
              <input
                type="text"
                name="description"
                className="width-480 input-form-dashboard-step mb-24"
                placeholder='توضیحات'
                value={formData.description}
                onChange={handleChange}
              />

              <div className='box width-480 d-flex flex-direction-column'>
                <div className='d-flex'>
                  <input
                    type="checkbox"
                    name="toggle"
                    className="toggle-button"
                    checked={selectAllTips}
                    onChange={handleSelectAllTips}
                  />
                  <label htmlFor="toggle-button-4" className="f-700 mr-4 color-black">
                    این خدمت را به همه تیپ ماشین ها اضافه کن
                  </label>
                </div>

                <div className='d-flex flex-direction-column mt-20'>
                  {tipList.map(tip => (
                    <div className='d-flex' key={tip.car_tip_id}>
                      <input
                        type="checkbox"
                        checked={formData.tips.some(t => t.car_tip_id === tip.car_tip_id)}
                        onChange={() => handleTipChange(tip.car_tip_id)}
                      />
                      <span className="checkmark mr-4">{tip.name}</span>
                    </div>
                  ))}
                </div>
              </div>


            </div>

            <div className='footer'>
              <div className='d-flex flx-start'>
                <div className="form-btn f-700 width-130 ml-16" onClick={handleSubmit} disabled={isSubmitting}>{isSubmitting ? 'در حال ارسال' : 'ایجاد خدمت'}</div>
                <div className="form-btn f-700 width-130 form-btn-transparent" onClick={handleCloseModal}>منصرف شدم</div>
              </div>
            </div>
          </ModalNewEmployee>

          <div className='dashboard-navbar nav-search-row'>
            <div className='title-40 f-700'>خدمات</div>

            <div>
              <div className="button-login" onClick={handleOpenModal}>
                ایجاد خدمت جدید
              </div>
            </div>
          </div>

          <div className='table-container'>
            <div className="main-container">
              <div className="table-row heading-row">
                <div className="row-item heading-row f-700">نام خدمت</div>
                <div className="row-item justify-content-left"></div>
              </div>

              {serviceListData.map((service) => (
                <div className="table-row" key={service.car_service_id}>
                  <div className="row-item f-700">{service.name}</div>
                  <div className="row-item justify-content-left">
                    <NavLink to={`/carvash-dashboard/service-detail/${service.car_service_id}`}>
                      <img src={leftArrowImage} alt="Arrow Images" className='pointer' />
                    </NavLink>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Pagination */}
          <div className="pagination-container">
            {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
              <button
                key={page}
                className={`pagination-button ${currentPage === page ? 'active' : ''}`}
                onClick={() => handlePagination(page)}
              >
                {page}
              </button>
            ))}
          </div>
        </div>
      }
    </>
  )
}

export default Services;
