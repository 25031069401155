import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import axios from 'axios';

Chart.register(...registerables);

const Reports = () => {
    const [reportType, setReportType] = useState('yesterday');
    const [categoryId, setCategoryId] = useState('');
    console.log(categoryId);
    const [data, setData] = useState({
        mostItem: {},
        mostMenu: {},
        mostAdditive: {},
        errorMessage: '',
    });
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [{
            label: 'تعداد فروش',
            data: [],
            fill: false,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: '#2C67F5',
            tension: 0.4,
        }],
    });
    const [chartError, setChartError] = useState('');
    const [menuOptions, setMenuOptions] = useState([]);

    useEffect(() => {
        const fetchMenuData = async () => {
            try {
                const response = await axios.get('https://jtechnical.ir/kiosk/api/v1/backend/restaurant/get-all-menu-and-sub-menu', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
                    },
                });

                // ایجاد یک آرایه جدید شامل منوها و زیرمنوها
                const menuData = response.data.result.map(menu => {
                    return [
                        { name: menu.name, category_id: menu.category_id },
                        ...menu.sub_menu
                    ];
                }).flat();

                setMenuOptions(menuData);
            } catch (error) {
                console.error('Error fetching menu data:', error);
            }
        };

        fetchMenuData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post('https://jtechnical.ir/kiosk/api/v1/backend/restaurant/best-serller-report', {
                    report_type: reportType
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
                    },
                });
                if (response.data.error) {
                    setData({
                        mostItem: {},
                        mostMenu: {},
                        mostAdditive: {},
                        errorMessage: response.data.error,
                    });
                } else {
                    const { most_item, most_additive, most_menu } = response.data;
                    setData({
                        mostItem: most_item,
                        mostAdditive: most_additive,
                        mostMenu: most_menu,
                        errorMessage: '',
                    });
                }
            } catch (error) {
                setData({
                    mostItem: {},
                    mostMenu: {},
                    mostAdditive: {},
                    errorMessage: 'در حال حاضر داده ای برای نمایش وجود ندارد!',
                });
                console.error('Error fetching data:', error);
            }
        };

        const fetchChartData = async () => {
            try {
                const response = await axios.post('https://jtechnical.ir/kiosk/api/v1/backend/restaurant/report-for-chart', {
                    report_type: reportType,
                    category_id: categoryId
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('RestaurantToken')}`,
                    },
                });
                if (response.data.error) {
                    setChartError(response.data.error);
                } else if (response.data.result) {
                    setChartData({
                        labels: response.data.result.labels,
                        datasets: [{
                            label: 'تعداد فروش',
                            data: response.data.result.data,
                            fill: false,
                            backgroundColor: 'rgba(75,192,192,0.4)',
                            borderColor: '#2C67F5',
                            tension: 0.4,
                        }],
                    });
                    setChartError('');
                }
            } catch (error) {
                console.error('Error fetching chart data:', error);
                setChartError('در حال حاضر داده ای برای نمایش وجود ندارد!');
            }
        };

        fetchData();
        fetchChartData();
    }, [reportType, categoryId]);

    const handleReportTypeChange = (event) => {
        setReportType(event.target.value);
    };

    const handleCategoryChange = (event) => {
        setCategoryId(event.target.value);
    };

    const options = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'تاریخ (شمسی)',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'تعداد فروش',
                },
                beginAtZero: true,
            },
        },
    };

    return (
        <div>
            <div className='dashboard-navbar space-between'>
                <div className='title-40 f-700'>گزارشات</div>
                <div>
                    <div className='mb-6 f-700'></div>
                    <select
                        className="input-form-dashboard-step mb-24 width-316"
                        value={reportType}
                        onChange={handleReportTypeChange}
                    >
                        <option value="today">امروز</option>
                        <option value="yesterday">دیروز</option>
                        <option value="7_days">هفته گذشته</option>
                        <option value="30_day">30 روز گذشته</option>
                        <option value="90_day">90 روز گذشته</option>
                    </select>
                </div>
            </div>

            {data.errorMessage ? (
                <div className='error-message'>{data.errorMessage}</div>
            ) : (
                <>
                    <div>
                        <div className='fs-24 f-700 mt-40 mb-8'>پرفروش ترین ها</div>
                        <div className='d-flex report justify-content-space-between'>
                            <div className='box d-flex flex-direction-column justify-content-space-between'>
                                <div className='d-flex justify-content-space-between'>
                                    <div className='color-sub'>پرفروش ترین آیتم</div>
                                    <div className='f-700 color-white'>{reportType}</div>
                                </div>
                                <div className='d-flex justify-content-space-between'>
                                    <div className='fs-24 f-700 color-white'>{data.mostItem.name || '...'}</div>
                                    <div className='fs-24 f-700 color-white'>{data.mostItem.count || '...'} عدد</div>
                                </div>
                            </div>

                            <div className='box d-flex flex-direction-column justify-content-space-between'>
                                <div className='d-flex justify-content-space-between'>
                                    <div className='color-sub'>پرفروش ترین منو</div>
                                    <div className='f-700 color-white'>{reportType}</div>
                                </div>
                                <div className='d-flex justify-content-space-between'>
                                    <div className='fs-24 f-700 color-white'>{data.mostMenu.name || '...'}</div>
                                    <div className='fs-24 f-700 color-white'>{data.mostMenu.count || '...'} عدد</div>
                                </div>
                            </div>

                            <div className='box d-flex flex-direction-column justify-content-space-between'>
                                <div className='d-flex justify-content-space-between'>
                                    <div className='color-sub'>پرفروش ترین افزودنی </div>
                                    <div className='f-700 color-white'>{reportType}</div>
                                </div>
                                <div className='d-flex justify-content-space-between'>
                                    <div className='fs-24 f-700 color-white'>{data.mostAdditive.name || '...'}</div>
                                    <div className='fs-24 f-700 color-white'>{data.mostAdditive.count || '...'} عدد</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <div>
                <div className='fs-24 f-700 mt-40 mb-8'>نمودار</div>
                <div className='d-flex'>
                    <div>
                        <select
                            className="input-form-dashboard-step mb-24 width-316"
                            value={reportType}
                            onChange={handleReportTypeChange}
                        >
                            <option value="today">امروز</option>
                            <option value="yesterday">دیروز</option>
                            <option value="7_days">هفته گذشته</option>
                            <option value="30_day">30 روز گذشته</option>
                            <option value="90_day">90 روز گذشته</option>
                        </select>
                    </div>

                    <div>
                        <select
                            className="input-form-dashboard-step mb-24 width-316"
                            value={categoryId}
                            onChange={handleCategoryChange}
                        >
                            <option value="">همه آیتم ها</option>
                            {menuOptions.map(option => (
                                <option key={option.category_id} value={option.category_id}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                {chartError ? (
                    <div className='error-message'>{chartError}</div>
                ) : (
                    <Line data={chartData} options={options} />
                )}
            </div>
        </div>
    );
};

export default Reports;