import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../components/FastFoodComponents/Header';
import ServiceList from '../../components/FastFoodComponents/ServiceList';
import FactorList from '../../components/FastFoodComponents/FactorList';
import Loading from '../../utils/Loading';

const FastFoodClient = () => {
    const [selectedCategory, setSelectedCategory] = useState(1);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [selectedServices, setSelectedServices] = useState([]);
    const [categoriesMain, setCategoriesMain] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        fetchMenuAndItems();
    }, []);

    const fetchMenuAndItems = async () => {
        
        setIsLoading(true);
        try {
            const response = await axios.get('https://jtechnical.ir/kiosk/api/v1/frontend/restaurant/show-menu-and-items');
            const data = response.data.result;
            setCategoriesMain(data);
        } catch (error) {
            console.error('Error fetching menu and items:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading ? <Loading /> :
                <div>
                    <Header
                        categoriesMain={categoriesMain}
                        setSelectedCategory={setSelectedCategory}
                        selectedCategory={selectedCategory}
                        setSelectedSubCategory={setSelectedSubCategory}
                        selectedSubCategory={selectedSubCategory}
                    />
                    <div className='d-flex justify-content-center'>
                        <ServiceList
                            setSelectedServices={setSelectedServices}
                            selectedServices={selectedServices}
                            selectedCategory={selectedSubCategory || selectedCategory}
                            categoriesMain={categoriesMain}
                        />
                        <FactorList
                            setSelectedServices={setSelectedServices}
                            selectedServices={selectedServices}
                        />
                    </div>
                </div>
            }
        </>
    );
};

export default FastFoodClient;
