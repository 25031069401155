import React, { useState, useEffect } from 'react';
import axios from 'axios';
import leftArrowImage from '../../assets/images/left-arrow.png';
import ImgUploadImage from '../../assets/images/file_upload.png';

import { NavLink } from "react-router-dom";
import Loading from '../../utils/Loading';
import { toast } from 'react-toastify';
import ModalNewEmployee from './ModalNewEmployee';

const Brands = () => {
    const [brandListData, setBrandListData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        image: ''
    });

    console.log(formData);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, image: file });
    };




    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);

            const response = await axios.post(
                `${process.env.REACT_APP_URL}/car-wash/add-brand`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${localStorage.getItem('CarWashToken')}`,
                    },
                }
            );

            handleCloseModal();
            fetchData();


            toast.success(response.data.success, { autoClose: 15000 });
            setFormData({})

        } catch (error) {
            console.error(error);
            toast.error(error.response.data.error, { autoClose: 15000 });

        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    const fetchData = async () => {
        setIsLoading(true)
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/car-wash/brand-list?page=${currentPage}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('CarWashToken')}`,
                },
            });

            const { brandList } = response.data;
            setBrandListData(brandList.data);
            setTotalPages(brandList.last_page);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false)
        }
    };


    const handlePagination = (page) => {
        setCurrentPage(page);
    };


    return (
        <>
            {isLoading ? <Loading /> :
                <div>
                    <ModalNewEmployee isOpen={isModalOpen} onClose={handleCloseModal}>
                        <div className="header">
                            <h2>ایجاد برند جدید</h2>
                        </div>


                        <div className='container'>
                            <div className='d-flex justify-content-center'>
                                <label htmlFor="image-upload" className='upload-img d-flex align-items-center justify-content-center pointer'>
                                    {formData.image ? (
                                        <img src={URL.createObjectURL(formData.image)} alt="Selected Image" className='pointer upload-img' width={32} height={32} />
                                    ) : (
                                        <div className='d-flex align-items-center flex-direction-column'>
                                            <img src={ImgUploadImage} alt="Profile Images" className='pointer' width={32} height={32} />
                                            <span>آپلود تصویر</span>
                                        </div>
                                    )}
                                </label>
                                <input
                                    type="file"
                                    id="image-upload"
                                    name="image"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleImageChange}
                                />
                            </div>

                            <div className='f-500 fs-16 mb-4'>نام برند</div>
                            <input
                                type="text"
                                name="name"
                                className="w-100 input-form-dashboard-step mb-24"
                                placeholder='نام برند'
                                value={formData.first_name}
                                onChange={handleChange}
                            />
                        </div>



                        <div className='footer'>
                            <div className='d-flex flx-start'>
                                <div className="form-btn f-700 width-130 ml-16" onClick={handleSubmit} disabled={isSubmitting}>{isSubmitting ? 'در حال ارسال' : 'ایجاد برند'}</div>
                                <div className="form-btn f-700 width-130 form-btn-transparent" onClick={handleCloseModal}>منصرف شدم</div>
                            </div>
                        </div>
                    </ModalNewEmployee>


                    <div className='dashboard-navbar nav-search-row'>
                        <div className='title-40 f-700'>برند خودرو</div>


                        <div>
                            <div className="button-login" onClick={handleOpenModal}>
                                ایجاد برند جدید
                            </div></div>
                    </div>

                    <div className='table-container'>
                        <div className="main-container">
                            <div className="table-row heading-row">
                                <div className="row-item heading-row f-700">نام برند</div>
                                <div className="row-item justify-content-left">

                                </div>
                            </div>


                            {brandListData.map((brand) => (
                                <div className="table-row">
                                    <div className="row-item f-700">
                                        {brand.name}
                                    </div>

                                    <div className="row-item justify-content-left">
                                        <NavLink to={`/carvash-dashboard/brand-detail/${brand.car_brand_id}`}>
                                            <img src={leftArrowImage} alt="Arrow Images" className='pointer' />
                                        </NavLink>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Pagination */}
                    <div className="pagination-container">
                        {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
                            <button
                                key={page}
                                className={`pagination-button ${currentPage === page ? 'active' : ''}`}
                                onClick={() => handlePagination(page)}
                            >
                                {page}
                            </button>
                        ))}
                    </div>

                </div>
            }
        </>
    )
}

export default Brands
