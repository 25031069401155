import React, { createContext, useContext, useReducer } from 'react';

const initialState = {
//   BeautySalon
// CarWash
// Pharmacy
// Restaurant
salonToken: localStorage.getItem('BeautySalonToken') || null,
  carvashToken: localStorage.getItem('CarWashToken') || null,
  restaurantToken: localStorage.getItem('RestaurantToken') || null,
  role: null,
};

const actions = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
};

const authReducer = (state, action) => {
  switch (action.type) {
    case actions.LOGIN:
      return {
        ...state,
        salonToken: action.payload.salonToken,
        carvashToken: action.payload.carvashToken,
        restaurantToken: action.payload.restaurantToken,
        role: action.payload.bussiness_name,
      };
    case actions.LOGOUT:
      return {
        ...state,
        salonToken: null,
        carvashToken: null,
        restaurantToken: null,
        role: null,
      };
    default:
      return state;
  }
}


const AuthContext = createContext();

// AuthProvider component
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>
  );
};

// Create the useAuth hook to access the context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
