import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import paymentSuccessImg from '../../assets/images/payment-success.png';
import paymentNotSuccessImg from '../../assets/images/payment-not-success.png';
import Barcode from 'react-barcode';

const PaymentStatus = () => {
    const [saveServices, setSaveServices] = useState(null);
    const [isDataSent, setIsDataSent] = useState(false);
    const [responseCode, setResponseCode] = useState(null);
    const [responseDescription, setResponseDescription] = useState("");
    const [showRetryButton, setShowRetryButton] = useState(true);
    const [countdown, setCountdown] = useState(10); // تایمر شمارش معکوس

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get("ResponseCode");
        const description = params.get("ResponseDescription");

        setResponseCode(code);
        setResponseDescription(description);

        if (code === "00") {
            const sendData = async () => {
                const services = localStorage.getItem("services");

                if (services && !isDataSent) {
                    const parsedServices = JSON.parse(services);
                    setSaveServices(parsedServices);

                    try {
                        const response = await axios.post(
                            "https://jtechnical.ir/kiosk/api/v1/frontend/restaurant/add-order",
                            { orders: parsedServices }
                        );

                        console.log("Data sent successfully:", response.data);

                        localStorage.removeItem("services");

                        setIsDataSent(true);

                        // پس از ارسال موفقیت‌آمیز داده‌ها، تابع printContent را اجرا کنید.
                        printContent();
                    } catch (error) {
                        console.error("Error sending data:", error);
                    }
                }
            };

            sendData();

            setTimeout(() => {
                setShowRetryButton(false);
            }, 3000);

            const countdownInterval = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);

            setTimeout(() => {
                clearInterval(countdownInterval);
                navigate("/");
            }, 10000);
        } else {
            const countdownInterval = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);

            setTimeout(() => {
                clearInterval(countdownInterval);
                navigate("/");
            }, 10000);
        }
    }, [isDataSent, location.search, navigate]);

    const printContent = () => {
        const printContents = document.querySelector('.print-page').innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }

    const handleRetryClick = () => {
        const params = new URLSearchParams(location.search);
        const amount = params.get("Amount");
        window.location.href = `http://localhost/kiosk-payment/?amount=${amount}`;
    };

    return (
        <>
        <div>
            <div className='carvash-client height-100vh'>
                <div className='d-flex flex-direction-column justify-content-center pay-waiting-step height-100vh'>
                    <div className='container'>
                        <div className='d-flex justify-content-center align-items-center box h-720'>
                            <div className='d-flex flex-direction-column align-items-center'>
                                <div>
                                    {responseCode && (
                                        <img
                                            src={responseCode === "00" ? paymentSuccessImg : paymentNotSuccessImg}
                                            alt={responseCode === "00" ? "Payment Successful" : "Payment Not Successful"}
                                            className='mb-24'
                                        />
                                    )}
                                </div>

                                {responseCode === "00" ? (
                                    <>
                                        <div className='fs-56 fw-700 pay-success color-green'>
                                            {responseDescription}
                                        </div>
                                        <div className='fs-35 fw-700 pay-success color-green'>
                                            لطفا رسید خود را بردارید
                                        </div>
                                    </>
                                ) : (
                                    <div className='fs-56 fw-700 pay-success color-red'>
                                        {responseDescription}
                                    </div>
                                )}

                                <div className='fs-35 fw-700 pay-success mt-20'>
                                    رفتن به صفحه اصلی بعد {countdown} ثانیه ...
                                </div>

                                {responseCode !== "00" && showRetryButton && (
                                    <button
                                        className="bg-blue text-white border-0 width-508 py-20 mt-40 fs-24 fw-700 rounded-100 pointer"
                                        onClick={handleRetryClick}
                                    >
                                        دوباره تلاش کنید
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="print-page justify-content-center">
            <div className='title-factor fw-700'>فاکتور پرداخت کافه بولوت</div>
            <table>
                <thead>
                    <tr>
                        <th>عنوان</th>
                        <th>تعداد</th>
                        <th>فی</th>
                        <th>جمع</th>
                    </tr>
                </thead>
                <tbody>
                    {saveServices && saveServices.map((service, index) => (
                        <React.Fragment key={index}>
                            <tr>
                                <td>{service.name}</td>
                                <td>{service.quantity}</td>
                                <td>{service.price}</td>
                                <td>{service.quantity * service.price}</td>
                            </tr>
                            {service.additives && service.additives.map((additive, idx) => (
                                <tr className="fs-8" key={idx}>
                                    <td>* {additive.name}</td>
                                    <td>{additive.quantity}</td>
                                    <td>{additive.price}</td>
                                    <td>{additive.quantity * additive.price}</td>
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                    <tr>
                        <td colSpan={3}>جمع مبلغ</td>
                        <td>
                            {saveServices && saveServices.reduce((total, service) => {
                                const serviceTotal = service.quantity * service.price;
                                const additivesTotal = service.additives ? service.additives.reduce((addTotal, additive) => addTotal + (additive.quantity * additive.price), 0) : 0;
                                return total + serviceTotal + additivesTotal;
                            }, 0)}
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className='title-factor mt-15'>از خلوتی راه سعادت نهراسید</div>

            <div className='barcode'>
                <Barcode value="541111"
                    format="CODE128"
                    width="2"
                    height="30"
                    displayValue="false"
                />
            </div>
        </div>
        </>
    );
};

export default PaymentStatus;
