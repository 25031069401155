import React from 'react';
import seviceListHimg from '../../assets/images/service20.jpg';
import seviceListH1img from '../../assets/images/service-list-11.png';

const Header = ({ categoriesMain, setSelectedCategory, selectedCategory, setSelectedSubCategory, selectedSubCategory }) => {
    const handleCategoryClick = (categoryId) => {
        setSelectedCategory(categoryId);
        setSelectedSubCategory(null); // Reset selected sub-category when main category is selected
    };

    const handleSubCategoryClick = (subCategoryId) => {
        setSelectedSubCategory(subCategoryId); // Set the selected sub-category
    };

    const selectedMainCategory = categoriesMain.find(category => category.restaurant_category_id === selectedCategory);

    return (
        <div className='sticky'>
            <div className='header bg-orange'>
                <div className='d-flex services justify-content-center align-items-center h-100'>
                    <div className='d-flex container overflow-overlay pointer'>
                        {categoriesMain.map((category, index) => (
                            <div
                                key={index}
                                className={`service bg-inside-orange ${selectedCategory === category.restaurant_category_id ? 'active' : ''}`}
                                onClick={() => handleCategoryClick(category.restaurant_category_id)}
                            >
                                <img
                                    className="imgS"
                                    src={seviceListHimg}
                                    // src={category['image-main']}
                                    alt="logo"
                                />
                                <div className='service-name'>{category['name']}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {selectedMainCategory?.sub_menu && (
                <div className='header bg-sub-orange'>
                    <div className='d-flex services justify-content-center align-items-center h-100'>
                        <div className='d-flex container overflow-overlay pointer'>
                            {selectedMainCategory.sub_menu.map((subCategory, index) => (
                                <div
                                    key={index}
                                    className={`service bg-inside-orange ${selectedSubCategory === subCategory.restaurant_category_id ? 'active' : ''}`}
                                    onClick={() => handleSubCategoryClick(subCategory.restaurant_category_id)}
                                >
                                    <img
                                        className="imgS"
                                        src={seviceListH1img}
                                        // src={subCategory.image}
                                        alt="logo"
                                    />
                                    <div className='service-name'>{subCategory.name}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Header;
