import React, { useState, useEffect } from 'react';
import axios from 'axios';
import leftArrowImage from '../../assets/images/left-arrow.png';
import { NavLink } from "react-router-dom";
import Loading from '../../utils/Loading';
import { toast } from 'react-toastify';
import ModalNewEmployee from './ModalNewEmployee';

const TypesCars = () => {
    const [brandsData, setBrandsData] = useState([]);
    const [servicesData, setServicesData] = useState([]);
    const [typesData, setTypesData] = useState([]);
    const [selectAllTips, setSelectAllTips] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        brands: [],
        services: []
    });


    const resetForm = () => {
        setFormData({
            name: '',
            description: '',
            brands: [],
            services: []
        });
    };
    
    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleBrandChange = (brandId, typeId) => {
        const existingBrand = formData.brands.find(brand => brand.car_brand_id === brandId);
        if (existingBrand) {
            const existingTypeIndex = existingBrand.type.findIndex(type => type.car_type_id === typeId);
            if (existingTypeIndex > -1) {
                existingBrand.type.splice(existingTypeIndex, 1);
            } else {
                existingBrand.type.push({ car_type_id: typeId });
            }
            setFormData({
                ...formData,
                brands: formData.brands.map(brand => brand.car_brand_id === brandId ? existingBrand : brand)
            });
        } else {
            setFormData({
                ...formData,
                brands: [...formData.brands, { car_brand_id: brandId, type: [{ car_type_id: typeId }] }]
            });
        }
    };

    const handleServiceChange = (serviceId) => {
        const existingServiceIndex = formData.services.findIndex(service => service.car_service_id === serviceId);
        if (existingServiceIndex > -1) {
            setFormData({
                ...formData,
                services: formData.services.filter(service => service.car_service_id !== serviceId)
            });
        } else {
            setFormData({
                ...formData,
                services: [...formData.services, { car_service_id: serviceId }]
            });
        }
    };

    const handleSelectAllServices = (e) => {
        setSelectAllTips(e.target.checked);
        if (e.target.checked) {
            const allServiceIds = servicesData.map(service => ({ car_service_id: service.car_service_id }));
            setFormData({ ...formData, services: allServiceIds });
        } else {
            setFormData({ ...formData, services: [] });
        }
    };

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);
            const response = await axios.post(
                `${process.env.REACT_APP_URL}/car-wash/add-tip`,
                formData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('CarWashToken')}`,
                    },
                }
            );

            handleCloseModal();
            fetchData();
            toast.success(response.data.success, { autoClose: 15000 });
            resetForm();


        } catch (error) {
            console.error(error);
            toast.error(error.response.data.error, { autoClose: 15000 });
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    const fetchData = async () => {
        setIsLoading(true);

        try {
            const brandsResponse = await axios.get(`${process.env.REACT_APP_URL}/car-wash/brands-and-type-car`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('CarWashToken')}` },
            });
            setBrandsData(brandsResponse.data.brandsList);
        } catch (error) {
            console.error('Error fetching brands:', error);
        }

        try {
            const servicesResponse = await axios.get(`${process.env.REACT_APP_URL}/car-wash/service-list-without-paginate`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('CarWashToken')}` },
            });
            setServicesData(servicesResponse.data.serviceList);
        } catch (error) {
            console.error('Error fetching services:', error);
        }

        try {
            const typesResponse = await axios.get(`${process.env.REACT_APP_URL}/car-wash/tip-list?page=${currentPage}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('CarWashToken')}` },
            });
            setTypesData(typesResponse.data.serviceList.data);
            setTotalPages(typesResponse.data.serviceList.last_page);
        } catch (error) {
            console.error('Error fetching types:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePagination = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            {isLoading ? <Loading /> :
                <div>
                    <ModalNewEmployee isOpen={isModalOpen} onClose={handleCloseModal}>
                        <div className="header">
                            <h2>تیپ ماشین جدید</h2>
                        </div>

                        <div className='container'>
                            <div className='f-500 fs-16 mb-4'>نام تیپ</div>
                            <input
                                type="text"
                                name="name"
                                className="width-480 input-form-dashboard-step mb-24"
                                placeholder='نام تیپ'
                                value={formData.name}
                                onChange={handleChange}
                            />

                            <div className='f-500 fs-16 mb-4'>توضیحات</div>
                            <input
                                type="text"
                                name="description"
                                className="width-480 input-form-dashboard-step mb-24"
                                placeholder='توضیحات'
                                value={formData.description}
                                onChange={handleChange}
                            />

                            <div>
                                <h2>برند و نوع ماشین</h2>
                                <p className='fs-14 mb-8'>برای برند، نوع ماشینی را که در  این تیپ قرار میگیرد را انتخاب کنید</p>
                                {brandsData.map((brand) => (
                                    <div key={brand.car_brand_id} className='box width-480 mb-8'>
                                        <div className='color-black fs-18 f-700'>{brand.name}</div>
                                        <div className='d-flex mt-8'>
                                            {brand.types.map((type) => (
                                                <div key={type.car_type_id} className='ml-8'>
                                                    <input
                                                        type="checkbox"
                                                        checked={
                                                            formData.brands.some(b =>
                                                                b.car_brand_id === brand.car_brand_id &&
                                                                b.type.some(t => t.car_type_id === type.car_type_id)
                                                            )
                                                        }
                                                        onChange={() => handleBrandChange(brand.car_brand_id, type.car_type_id)}
                                                    />
                                                    <span className="checkmark mr-4">{type.name}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className='mt-24'>
                                <h2>خدمت</h2>
                                <p className='fs-14 mb-8'>خدماتی که میخواهید به این دسته بندی اضافه شوند را انتخاب کنید و سپس از طریق صفحه تیپ ماشین ها، قیمت هرکدام از خدمات را وارد کنید.</p>

                                <div className='box width-480 d-flex flex-direction-column'>
                                    <div className='d-flex'>
                                        <input
                                            type="checkbox"
                                            name="toggle"
                                            className="toggle-button"
                                            checked={selectAllTips}
                                            onChange={handleSelectAllServices}
                                        />
                                        <label htmlFor="toggle-button-4" className="f-700 mr-4 color-black">
                                            این خدمت را به همه تیپ ماشین ها اضافه کن
                                        </label>
                                    </div>

                                    <div className='d-flex flex-direction-column mt-20'>
                                        {servicesData.map((service) => (
                                            <div key={service.car_service_id} className='d-flex justify-content-space-between'>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        checked={formData.services.some(s => s.car_service_id === service.car_service_id)}
                                                        onChange={() => handleServiceChange(service.car_service_id)}
                                                    />
                                                    <span className="checkmark mr-10">{service.name}</span>
                                                </div>

                                                <div>
                                                    {service.price}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='footer'>
                            <div className='d-flex flx-start'>
                                <div className="form-btn f-700 width-130 ml-16" onClick={handleSubmit} disabled={isSubmitting}>
                                    {isSubmitting ? 'در حال ارسال' : 'ایجاد تیپ'}
                                </div>
                                <div className="form-btn f-700 width-130 form-btn-transparent" onClick={handleCloseModal}>
                                    منصرف شدم
                                </div>
                            </div>
                        </div>
                    </ModalNewEmployee>

                    <div className='dashboard-navbar nav-search-row'>
                        <div className='title-40 f-700'>تیپ ماشین</div>
                        <div>
                            <div className="button-login" onClick={handleOpenModal}>
                                ایجاد تیپ ماشین جدید
                            </div>
                        </div>
                    </div>

                    <div className='table-container'>
                        <div className="main-container">
                            <div className="table-row heading-row">
                                <div className="row-item heading-row f-700">نام تیپ</div>
                                <div className="row-item justify-content-left"></div>
                            </div>

                            {typesData.map((service) => (
                                <div className="table-row" key={service.car_tip_id}>
                                    <div className="row-item f-700">
                                        {service.name}
                                    </div>
                                    <div className="row-item justify-content-left">
                                        <NavLink to={`/carvash-dashboard/type-detail/${service.car_tip_id}`}>
                                            <img src={leftArrowImage} alt="Arrow Images" className='pointer' />
                                        </NavLink>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="pagination-container">
                        {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
                            <button
                                key={page}
                                className={`pagination-button ${currentPage === page ? 'active' : ''}`}
                                onClick={() => handlePagination(page)}
                            >
                                {page}
                            </button>
                        ))}
                    </div>
                </div>
            }
        </>
    );
}

export default TypesCars;
